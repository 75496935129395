import { Header, StyledButton, StyledContainer } from '@/constants/themes';
import useAlchemystStoreForAi, {
  updateSnackbarEntry,
} from '@/hooks/ai/client/useAlchemystStoreForAi';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Box, Input, Typography } from '@mui/material';

import { createTimeoutSignal } from '@/utils/signalConfig';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import SettingFields from './SmartSettingFields';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';

function SmartSettingsPage(): React.ReactNode {
  const [isUploading, setIsUploading] = useState(false);
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const [hostName, setHostName] = useState('http://localhost:3000');
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (window) {
      setHostName(window.location.origin);
    }
  }, []);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploading(true);

    // Prepare file metadata
    const fileMetadata = {
      fileName: file.name,
      fileType: file.type,
      fileSize: file.size,
      lastModified: file.lastModified,
    };
    const notificationId = `platform.resource.${fileMetadata.fileName}.${fileMetadata.fileType}`;

    try {
      const contextProcessorUrl = `${hostName}/api/context/add`;
      const worker = new Worker('/workers/workers/upload_patch.js', {
        type: 'module',
      }); // TODO: Replace with actual worker path

      const workerOnMessageHandler = (
        data: {
          type: 'progress' | 'complete' | 'error';
          payload: { value: number; message: string };
        } & Record<string, any>,
        notificationId: string
      ) => {
        console.log('Received payload...');
        const { type, payload } = data;
        console.log(payload);

        switch (type) {
          case 'progress':
            console.log(`Processing progress: ${JSON.stringify(payload)}%`);
            updateSnackbarEntry({
              notificationId,
              ...payload,
            });
            break;
          case 'complete':
            console.log('File processing and uploading complete');
            updateSnackbarEntry({
              notificationId,
              ...payload,
              type: 'progress',
            });
            break;
          case 'error':
            console.error('Error in worker:', payload);
            break;
        }
      };

      worker.onmessage = (event) =>
        workerOnMessageHandler(event.data, notificationId);

      worker.onerror = (error) => {
        console.error('Error in worker:', error);
      };

      const fileContent = await readFileContent(file);
      console.log(
        'File content = ',
        fileContent.slice(Math.min(60, fileContent.length))
      );
      const apiUrl = contextProcessorUrl;

      if (!apiUrl) {
        throw new Error('CONTEXT_PROCESSOR_URL is not defined');
      }

      // await storeFileContentInIndexedDB(file.name, fileContent);

      // TODO: Replace with actual user ID

      const fetchUserIdResponse = await fetchWithRewrites('/api/profile', {
        signal: createTimeoutSignal(),
      });
      const fetchUserIdJson: { userId: string } =
        await fetchUserIdResponse.json();
      const userId = fetchUserIdJson.userId;
      console.log('Received userId = ', userId);

      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            type: 'progress',
            value: 0,
            message: 'Started uploading document',
            severity: 'info',
            notificationId,
          },
        ],
      });

      console.log('File reading complete, posting message to worker...');
      await worker.postMessage({ fileContent, apiUrl, fileMetadata, userId });
    } catch (error) {
      console.error('Error processing file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const readFileContent = async (file: File): Promise<string> => {
    console.log('Reading file...');
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    switch (fileExtension) {
      case 'pdf':
        return readPdfContent(file);
      case 'docx':
        return readDocxContent(file);
      case 'xlsx':
        return JSON.stringify(await readXlsxContent(file));
      default:
        console.log('Reading text file...');
        return file.text();
    }
  };

  const readPdfContent = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);

    console.log('Getting PDF text...');
    try {
      const response = await fetchWithRewrites('/api/upload', {
        method: 'POST',
        body: formData,
        signal: createTimeoutSignal(),
      });

      if (!response.ok) {
        console.log('Failed to process PDF');
      }

      const responseData: Record<string, any> & { text: string } =
        await response.json();

      console.log('Finished uploading pdf file to context processor.');

      return responseData.text;
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const readDocxContent = async (file: File): Promise<string> => {
    console.log('Reading Docx File...');
    // This function would use the mammoth package to read DOCX content
    // For demonstration, we'll use a placeholder implementation
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve('DOCX content placeholder');
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const readXlsxContent = async (file: File): Promise<string[][]> => {
    console.log('Reading XLSX File...');

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result;

        if (result) {
          try {
            const data = new Uint8Array(result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            console.log(jsonData);
            // @ts-ignore
            resolve(jsonData);
          } catch (error) {
            console.error('Error parsing XLSX file:', error);
            reject(error);
          }
        } else {
          reject(new Error('Failed to read file'));
        }
      };

      reader.onerror = () => {
        reject(new Error('File reading failed'));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const storeFileContentInIndexedDB = async (
    fileName: string,
    content: string
  ) => {
    console.log('Writing to IndexedDB...');
    const dbName = 'FileContentsDB';
    const storeName = 'fileContents';
    const version = 1;

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, version);

      request.onerror = (event) => reject('IndexedDB error');

      request.onsuccess = (event) => {
        const db = request.result;
        const transaction = db.transaction([storeName], 'readwrite');
        const store = transaction.objectStore(storeName);
        const addRequest = store.put({ fileName, content });

        addRequest.onerror = () => reject('Error storing file content');
        addRequest.onsuccess = () => resolve(undefined);
      };

      request.onupgradeneeded = (event) => {
        const db = request.result;
        db.createObjectStore(storeName, { keyPath: 'fileName' });
      };
    });
  };

  return (
    <StyledContainer maxWidth="xl">
      <Header>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'space-between'}
          width={'100%'}
          gap={2}
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              Smart Setting
            </Typography>
            <Typography variant="body1" marginTop={2}>
              Manage your smart settings here.
            </Typography>
          </Box>
          <StyledButton
            variant="contained"
            // @ts-ignore
            component="label"
            startIcon={<AutoFixHighIcon />}
            sx={{
              padding: '8px 26px',
              fontSize: '16px',
              borderRadius: '8px',
            }}
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Upload & Autofill'}
            <Input
              type="file"
              hidden
              className="hidden"
              inputProps={{ accept: '.csv,.docx,.doc,.pdf,.txt' }}
              onChange={handleFileUpload}
              disabled={isUploading}
            />
          </StyledButton>
        </Box>
      </Header>
      <SettingFields />
    </StyledContainer>
  );
}

export default SmartSettingsPage;
