import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { AutoEmail } from '@/types/email';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Avatar from '@mui/material/Avatar/Avatar';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Divider from '@mui/material/Divider/Divider';
import Grid from '@mui/material/Grid/Grid';
import TextField from '@mui/material/TextField/TextField';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography/Typography';
import { useNavigate } from 'raviger';
import { useCallback, useEffect, useState } from 'react';

export interface EmailCardProps {
  email: AutoEmail;
  maxRows?: number;
  fullWidth?: boolean;
  handleApprove: (email: AutoEmail) => void;
  handleEmailChange: (updatedEmail: AutoEmail) => void;
  handleReject: (email: AutoEmail) => void;
}

type EmailItem = {
  _id: string;
  emailId: string;
};

const EmailCard = ({
  email,
  maxRows,
  fullWidth,
  handleApprove,
  handleEmailChange,
  handleReject,
}: EmailCardProps) => {
  const navigate = useNavigate();

  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const [fetchInboxEmail, setFetchInboxEmails] = useState<{
    data: EmailItem[];
    loading: boolean;
  }>({
    data: [],
    loading: false,
  });
  // const [token, setToken] = useState<string | null>(null);
  // // const { getToken } = fetchWithRewrites(;

  // const getAuthToken = async () => {
  //   const token = await getToken();
  //   setToken(token);
  // };

  // useEffect(() => {
  //   getAuthToken();
  // }, []);

  const handleFieldChange = useCallback(
    (field: keyof AutoEmail, value: string) => {
      handleEmailChange({ ...email, [field]: value });
    },
    [email, handleEmailChange]
  );

  useEffect(() => {
    const fetchEmails = async () => {
      setFetchInboxEmails((prev) => ({
        ...prev,
        loading: true,
      }));
      try {
        const response = await fetchWithRewrites(
          '/api/inbox/list?emailsOnly=true',
          {
            signal: createTimeoutSignal(),
          }
        );
        if (!response.ok) throw new Error('Failed to fetch emails');
        const emails = await response.json();
        if (emails)
          setFetchInboxEmails({
            data: emails.data,
            loading: false,
          });
        handleFieldChange('from', emails.data[0]?.emailId);
        const inboxEmailsList = emails.data.map(
          (item: EmailItem) => item.emailId
        );
        setStoreState({
          inboxEmails: inboxEmailsList,
        });

        // console.log(emails.data[0]);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 2,
        paddingX: 4,
        marginBottom: 3,
        borderRadius: 2,
        background:
          'linear-gradient(268.4deg, rgba(255, 160, 91, 0.1) 1.52%, rgba(31, 156, 154, 0.1) 98.82%)',
      }}
    >
      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              sx={{ bgcolor: 'blue' }}
              aria-label="email"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ0FpBg5Myb9CQ-bQpFou9BY9JXoRG6208_Q&s"
            >
              {email.to.charAt(0).toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item xs>
            <TextField
              label="To"
              variant="outlined"
              value={email.to}
              onChange={(e) => handleFieldChange('to', e.target.value)}
              maxRows={maxRows}
              fullWidth={!!fullWidth}
            />
            {
              <>
                <Select
                  disabled={
                    fetchInboxEmail.loading || fetchInboxEmail.data.length === 0
                  }
                  value={email.from}
                  variant="outlined"
                  onChange={changeFromEmail}
                  displayEmpty
                  fullWidth={!!fullWidth}
                >
                  <MenuItem value="" disabled>
                    Select from which email to send
                  </MenuItem>
                  {fetchInboxEmail.data?.map((item) => (
                    <MenuItem key={item._id} value={item.emailId}>
                      {item.emailId}
                    </MenuItem>
                  ))}
                </Select>
                {!fetchInboxEmail.loading &&
                  fetchInboxEmail.data.length === 0 && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      alignItems="center"
                      sx={{
                        marginTop: 2,
                      }}
                    >
                      <Typography variant="subtitle1" id="imap-modal-title">
                        No inbox emails found ! Please create one
                      </Typography>
                      <Button
                        onClick={() => {
                          navigate('/inbox');
                        }}
                      >
                        Create
                      </Button>
                    </Box>
                  )}
                {fetchInboxEmail.loading && <div>Fetching emails ...</div>}
              </>
            }
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">
              {new Date(email.date).toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ marginY: 2 }} />
      <Box mb={2}>
        <TextField
          label="Subject"
          variant="outlined"
          multiline
          maxRows={1}
          value={email.subject}
          onChange={(e) => handleFieldChange('subject', e.target.value)}
          fullWidth={!!fullWidth}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Content"
          variant="outlined"
          multiline
          minRows={4}
          maxRows={maxRows}
          value={email.body}
          onChange={(e) => handleFieldChange('body', e.target.value)}
          fullWidth={!!fullWidth}
        />
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Tooltip title="Approve">
          <span>
            <Button
              variant="contained"
              color="success"
              sx={{ marginRight: 2 }}
              onClick={() => handleApprove(email)}
              startIcon={<CheckCircle />}
            >
              Approve
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Reject">
          <span>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleReject(email)}
              startIcon={<Cancel />}
            >
              Reject
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );

  function changeFromEmail(e: SelectChangeEvent<string>): void {
    handleFieldChange('from', e.target.value);
  }
};

export default EmailCard;
