import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { Autocomplete, Chip, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { countryListWithCode } from '../../constants/country';
import { jobTitles } from '../../constants/jobTitle';
import { sectors } from '../../constants/sectors';
import { technologies } from '../../constants/technologies';
import type { CustomSelectProps, Option } from './types';

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  placeholder = 'Select...',
  multiselect = false,
  value: initialValue,
  onChange,
  large_options = false,
  large_options_key,
  options = [],
  sx = {},
}) => {
  // validate value
  if (multiselect && !Array.isArray(initialValue)) {
    console.warn('Initial value must be an array for multiselect');
    return null;
  }
  if (!multiselect && Array.isArray(initialValue)) {
    console.warn('Initial value must be a string for single select');
    return null;
  }

  // Initialize value based on multiselect and initialValue
  const [value, setValue] = useState<string | string[]>(
    initialValue ?? (multiselect ? [] : '')
  );

  // State for large option lists
  const [largeOptionList, setLargeOptionList] = useState<string[]>([]);

  const theme = useAlchemystStoreForAi((state) => state.theme);

  // Effect to populate large option lists
  useEffect(() => {
    if (large_options && large_options_key) {
      let newList: string[] = [];
      switch (large_options_key) {
        case 'country':
          newList = countryListWithCode.map((country) => country.name);
          break;
        case 'job_title':
          newList = jobTitles;
          break;
        case 'sector':
          newList = sectors;
          break;
        case 'technology':
          newList = technologies;
          break;
        default:
          console.warn(`Unsupported large_options_key: ${large_options_key}`);
          break;
      }
      setLargeOptionList(newList);
    } else {
      setLargeOptionList([]);
    }
  }, [large_options, large_options_key]);

  // Determine available options
  const availableOptions: Option[] = large_options
    ? largeOptionList.map((item) => ({ value: item, option_name: item }))
    : Array.isArray(options)
      ? options
      : [];

  // Change handler
  const handleChange = (
    _: React.SyntheticEvent,
    newValue: string | string[] | null
  ) => {
    const updatedValue = newValue ?? (multiselect ? [] : '');
    setValue(updatedValue);
    onChange(updatedValue);
  };

  // Find option label
  const getOptionLabel = (option: string) => {
    if (!option) return '';
    const foundOption = (options as Option[]).find(
      (opt) => opt.value === option
    );
    return foundOption?.option_name || option;
  };

  // Render tags for multiselect
  const renderTags = (
    tagValue: string[],
    getTagProps: (args: { index: number }) => {}
  ) =>
    tagValue.map((option, index) => (
      <Chip
        color="primary"
        label={
          (options as Option[]).find((opt) => opt.value === option)
            ?.option_name || option
        }
        variant="outlined"
        {...getTagProps({ index })}
        key={index}
      />
    ));

  return (
    <Autocomplete
      disableCloseOnSelect={multiselect}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, selectedValue) => option === selectedValue}
      multiple={multiselect}
      onChange={handleChange}
      options={availableOptions.map((option) => option.value)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={name}
          margin="normal"
          placeholder={placeholder}
          sx={{
            backgroundColor: theme === 'dark' ? '#333' : '#fff',
            color: theme === 'dark' ? '#fff' : '#000',
            ...sx,
          }}
          variant="outlined"
        />
      )}
      renderTags={renderTags}
      value={value}
    />
  );
};

export default CustomSelect;
