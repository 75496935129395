import { Box, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import type { CustomSwitchProps } from './types';

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  name,
  value: _value = false,
  onChange,
  
  sx,
}) => {
  const [value, setValue] = useState<boolean>(_value);
  const handleOnChange = () => {
    setValue(!value);
    onChange(!value);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <Typography component="div" variant="h6">
        {name}
      </Typography>
      <Switch
        aria-label="switch"
        checked={value}
        color="primary"
        name={name}
        onChange={handleOnChange}
        sx={{ marginTop: '0.5rem' }}
      />
    </Box>
  );
};
export default CustomSwitch;
