import { ActionSelectorBox } from '@/components/common/ActionSelectorBox';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import { CampaignWorkflow, StepConfig } from '@/types/campaign';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import StepsEditor from './StepsEditor';

interface CampaignSequenceProps {}

const CampaignSequence: React.FC<CampaignSequenceProps> = () => {
  const AVAILABLE_INTEGRATIONS = ['Email', 'Whatsapp', 'Telegram'];

  const { campaignData, selectedActions, setStoreState } =
    useCampaignBuilderStore((store) => ({
      campaignData: store.campaignData,
      selectedActions: store.metadata.actions ?? [],
      setStoreState: store.setStoreState,
    }));
  const selectedLeads = useAlchemystStoreForAi((store) => store.selectedLeads);

  const [openModal, setOpenModal] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState<StepConfig | null>(null);

  const handleAddStep = (): void => {
    const currentSteps = campaignData?.stepConfig || [];
    const maxId = currentSteps.reduce(
      (max, step) => Math.max(max, step.id),
      -1
    );
    const newId = maxId + 1;

    setCurrentStep({
      id: newId,
      from: [],
      message: {
        type: 'email',
        subject: '',
        body: '',
      },
      waitTime: 1,
      aiGenerated: false,
      actions: selectedActions,
      name: '',
      useAi: {
        create: false,
        modify: false,
      },
    });

    setOpenModal(true);
  };

  const editStep = (step: StepConfig, id: number) => {
    setCurrentStep(step);
    setOpenModal(true);
  };

  const deleteStep = (id: number) => {
    if (!campaignData) return;
    const updatedSequenceSteps = campaignData.stepConfig.filter(
      (step) => step.id !== id
    );
    setStoreState({
      campaignData: {
        ...campaignData,
        stepConfig: updatedSequenceSteps,
      },
    });
  };

  // useEffect(() => {
  //   console.log(campaignData, 'SELECTED ACTIONS OUTPUT');
  // }, [selectedActions]);

  const setSelectedActions = (
    selectedActions: string | string[],
    idx?: number
  ) => {
    let selectedActionsParsed = [];

    if (typeof selectedActions === 'string') {
      selectedActionsParsed = selectedActions.split(',');
    } else {
      selectedActionsParsed = selectedActions;
    }

    // TODO logic to be refined
    if (!idx) {
      setStoreState({ metadata: { actions: selectedActionsParsed } });
      setStoreState({
        // @ts-ignore
        campaignData: {
          ...campaignData,
          metadata: { actions: selectedActionsParsed },
        },
      });
    } else {
      const updatedStepConfig = campaignData?.stepConfig.map(
        (config, stepConfigEntryIdx) => {
          if (idx === config.id) {
            return { ...config, actions: selectedActionsParsed };
          } else return config;
        }
      );
      if (updatedStepConfig) {
        setStoreState({ metadata: { actions: selectedActionsParsed } });
        setStoreState({
          // @ts-ignore
          campaignData: {
            ...campaignData,
            metadata: { actions: selectedActionsParsed },
            stepConfig: updatedStepConfig,
          },
        });
      }
    }
  };

  const saveStepInfo = (id: number) => {
    if (!currentStep) return;
    if (!campaignData) return;

    setStoreState({
      campaignData: {
        ...campaignData,
        stepConfig: campaignData.stepConfig.some((step) => step.id === id)
          ? campaignData.stepConfig.map((step) =>
              step.id === id ? currentStep : step
            )
          : [...campaignData.stepConfig, currentStep],
      },
    });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Campaign Sequence
      </Typography>
      <Stepper orientation="vertical">
        <Step>
          <StepLabel>Start</StepLabel>
        </Step>
        {campaignData?.stepConfig.map((step, idx) => (
          <Step key={step.id}>
            <StepLabel>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent={'baseline'}
              >
                <Typography>
                  {idx === 0 ? 'Warmup Content' : `Follow-Up Content ${idx}`}
                </Typography>
                <Typography>via</Typography>
                <Box>
                  <ActionSelectorBox
                    setActions={setSelectedActions}
                    stepConfigIdx={idx}
                    actions={selectedActions || []}
                    allActions={AVAILABLE_INTEGRATIONS}
                  />
                  <IconButton onClick={() => editStep(step, idx)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteStep(step.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </StepLabel>
            <StepContent>
              <Typography>
                Wait Time: {step.waitTime}{' '}
                {step.waitTime === 1 ? 'Day' : 'Days'}
              </Typography>
              <Typography>Subject:</Typography>
              <Box p={2}>{step.message.subject}</Box>
            </StepContent>
          </Step>
        ))}
        <Step>
          <StepLabel>
            <Stack
              direction={'row'}
              display="flex"
              gap={2}
              alignContent={'baseline'}
            >
              <Button onClick={handleAddStep} variant="contained">
                {campaignData?.stepConfig.length === 0
                  ? 'Add Warmup'
                  : 'Add Follow-Up'}
              </Button>
              <Typography>via</Typography>
              <ActionSelectorBox
                // stepConfigIdx={totalStepsCount}
                allActions={AVAILABLE_INTEGRATIONS}
                actions={selectedActions || []}
                setActions={setSelectedActions}
              />
            </Stack>
          </StepLabel>
        </Step>
      </Stepper>
      <StepsEditor
        selectedLeads={selectedLeads}
        openModal={openModal}
        setOpenModal={setOpenModal}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        onSave={(id) => saveStepInfo(id)}
      />
    </Box>
  );
};

export default CampaignSequence;
