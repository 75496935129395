import { sampleIntegrationsMenu } from '@/constants';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Modal,
} from '@mui/material';
import IntegrationCard from './IntegrationCard';
import { useState } from 'react';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import CircularProgress from '@mui/material/CircularProgress';

const Integrations = () => {
  const installedIntegrations = sampleIntegrationsMenu.filter(
    (item) => item.installed
  );

  const [configModals, setConfigModals] = useState({
    whatsapp: false,
    telegram: false,
    slack: false,
    hubspot: false,
  });

  return (
    <Box>
      <Typography variant="h6" marginY={3}>
        Installed {installedIntegrations.length}/{sampleIntegrationsMenu.length}{' '}
        integrations.
      </Typography>
      <Box>
        {installedIntegrations.map((item, index) => (
          <IntegrationCard
            key={index}
            item={item}
            setConfigModals={setConfigModals}
          />
        ))}
      </Box>
      <WhatsAppConfigModal
        openModal={configModals.whatsapp}
        setConfigModals={setConfigModals}
      />
    </Box>
  );
};

export default Integrations;

interface WhatsappModalProps {
  openModal: boolean;
  setConfigModals: React.Dispatch<
    React.SetStateAction<{
      whatsapp: boolean;
      telegram: boolean;
      slack: boolean;
      hubspot: boolean;
    }>
  >;
}

const WhatsAppConfigModal = (props: WhatsappModalProps) => {
  const { openModal, setConfigModals } = props;

  const [formValues, setFormValues] = useState({
    accessToken: '',
    phoneNumberId: '',
    appId: '',
    appSecret: '',
    verifyToken: '',
    apiKey: '',
  });
  const [savingIntegration, setSavingIntegration] = useState(false);

  const handleInputChange = (field: string, value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };
  const closeModal = () => {
    setConfigModals((prev) => ({
      ...prev,
      whatsapp: false,
    }));
  };
  const handleFormSubmit = async () => {
    setSavingIntegration(true);
    try {
      const response = await fetchWithRewrites(
        `/api/maya/integrations/whatsapp/init`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formValues),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Response from API:', result);

      setFormValues((prev) => ({
        ...prev,
        accessToken: '',
        phoneNumberId: '',
        appId: '',
        appSecret: '',
        verifyToken: '',
        apiKey: '',
      }));
      closeModal();
    } catch (error) {
      console.error('Error while saving WhatsApp integration:', error);
    } finally {
      setSavingIntegration(false);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="integration-settings-modal"
      aria-describedby="integration-settings-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            pb: 4,
          }}
        >
          Integration Settings
        </Typography>
        <Stack spacing={2}>
          <TextField
            label="Access Token"
            variant="outlined"
            fullWidth
            value={formValues.accessToken}
            onChange={(e) => handleInputChange('accessToken', e.target.value)}
          />
          <TextField
            label="Phone Number ID"
            variant="outlined"
            fullWidth
            value={formValues.phoneNumberId}
            onChange={(e) => handleInputChange('phoneNumberId', e.target.value)}
          />
          <TextField
            label="App ID"
            variant="outlined"
            fullWidth
            value={formValues.appId}
            onChange={(e) => handleInputChange('appId', e.target.value)}
          />
          <TextField
            label="App Secret"
            variant="outlined"
            fullWidth
            value={formValues.appSecret}
            onChange={(e) => handleInputChange('appSecret', e.target.value)}
          />
          <TextField
            label="Verify Token"
            variant="outlined"
            fullWidth
            value={formValues.verifyToken}
            onChange={(e) => handleInputChange('verifyToken', e.target.value)}
          />
          <TextField
            label="Platform Api Key"
            variant="outlined"
            fullWidth
            value={formValues.apiKey}
            onChange={(e) => handleInputChange('apiKey', e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
            disabled={savingIntegration}
            startIcon={savingIntegration && <CircularProgress size={24} />}
          >
            {savingIntegration ? 'Saving...' : 'Save'}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
