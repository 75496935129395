import { fieldOptions } from '@/constants/campaigns/fieldOptions';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';

interface AddFieldOptionsProps {
  onClick: (fieldType: string) => void;
}

const AddFieldOptions: React.FC<AddFieldOptionsProps> = ({ onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 2,
      }}
    >
      <Typography gutterBottom mb={2} textAlign="start" variant="h5">
        Drag and drop to the editor
      </Typography>
      <Grid container spacing={3}>
        {fieldOptions.map((field, index) => (
          <Grid item key={field.type} xs={6}>
            <Draggable draggableId={`${field.type}${index}`} index={index}>
              {(provided) => (
                <Card
                  onClick={() => {
                    onClick(field.type);
                  }}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  sx={{
                    height: 170,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer',
                  }}
                >
                  <CardMedia
                    alt={field.name}
                    component="img"
                    height="100"
                    image={field.image}
                    sx={{
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="body1">
                      {field.name}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Draggable>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AddFieldOptions;
