import { StyledButton } from '@/constants/themes';
import useTemplateBuilderStore from '@/hooks/useTemplateBuilderStore';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';

interface StepNavigationProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
}

const StepNavigation: React.FC<StepNavigationProps> = ({
  activeStep,
  setActiveStep,
}) => {
  const currentCampaignTemplate = useTemplateBuilderStore(
    (store) => store.currentCampaignTemplate
  );

  // return if there's no campaign template, {{fallback}}
  if (!currentCampaignTemplate) return null;

  const { steps } = currentCampaignTemplate;
  const isPrevDisabled = activeStep === 0;
  const isNextDisabled = activeStep === steps.length - 1;

  const handlePrevClick = () => setActiveStep(activeStep - 1);
  const handleNextClick = () => setActiveStep(activeStep + 1);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '2rem',
        paddingX: '10%',
      }}
    >
      <StyledButton
        disabled={isPrevDisabled}
        onClick={handlePrevClick}
        size="large"
        startIcon={<ArrowLeft />}
        sx={{
          padding: '10px 20px',
          fontSize: '15px',
          borderRadius: '8px',
        }}
        variant="contained"
      >
        Prev
      </StyledButton>

      <StyledButton
        disabled={isNextDisabled}
        onClick={handleNextClick}
        size="large"
        endIcon={<ArrowRight />}
        sx={{
          padding: '10px 20px',
          fontSize: '15px',
          borderRadius: '8px',
        }}
        variant="contained"
      >
        Next
      </StyledButton>
    </Box>
  );
};

export default StepNavigation;
