import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

//icons
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const content = {
  tokenUsage: [
    'Each lead consumes 1 token. For 100 leads: 100 x 1 = 100 tokens.',
    'Each personalized email consumes tokens, hence 100 x 7 = 700 tokens.',
    'Each data augmentation consumes 8-10 tokens depending on the augmentation technique.',
  ],
  subscriptionDetails: {
    annual:
      'If you have an annual subscription, your tokens will be carried over to the next one.',
    monthly:
      'If you are a monthly subscriber, your tokens will be reset at the end of each month.',
  },
};

const fetchBilling = async () => {
  const response = await fetchWithRewrites('/api/billing');
  const res_json = await response.json();

  console.log(res_json, 'the response');

  return res_json;
};
const BillingComponent = () => {
  const [billingDetails, setBillingDetails] = useState({
    leads: NaN,
    responses: NaN,
  });

  const { snackbarEntries, setStoreState } = useAlchemystStoreForAi(
    (store) => ({
      snackbarEntries: store.snackbarEntries,
      setStoreState: store.setStoreState,
    })
  );

  useEffect(() => {
    fetchBilling()
      .then((response) => setBillingDetails(response))
      .catch((error) => {
        console.log(error);
        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              message: 'An error occurred while fetching billing details',
              severity: 'error',
              type: 'alert',
              autoHideDuration: 6000,
            },
          ],
        });
      });
  }, []);

  return (
    <Box sx={(theme) => ({ background: theme.palette.background.default })}>
      <Stack flex={1} flexDirection={'column'} spacing={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              textAlign: 'left',
            }}
          >
            <Typography variant="h4">{billingDetails.leads}</Typography>
            <Typography variant="body1">tokens for leads</Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'left',
              paddingLeft: '0.8rem',
            }}
          >
            <Typography variant="h4">{billingDetails.responses}</Typography>
            <Typography variant="body1">tokens for responses</Typography>
          </Box>
        </Box>
        <Stack
          spacing={3}
          //   sx={{
          //     paddingTop: '1.5rem',
          //   }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: '0.8rem',
            }}
          >
            <Typography variant="h6" gutterBottom color="primary">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon sx={{ marginRight: '8px' }} />
                Token Usage
              </Box>
            </Typography>

            {content.tokenUsage.map((item, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{ marginBottom: '8px' }}
              >
                {item}
              </Typography>
            ))}
          </Paper>

          <Paper
            elevation={3}
            sx={{
              padding: '1rem',
            }}
          >
            <Typography variant="h6" gutterBottom color="primary">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon sx={{ marginRight: '8px' }} />
                Subscription Details
              </Box>
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: '4px', fontWeight: 'bold' }}
            >
              {content.subscriptionDetails.annual}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {content.subscriptionDetails.monthly}
            </Typography>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BillingComponent;

const Blockquote = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        borderLeft: '4px solid #1976d2', // Accent border
        padding: '16px 24px',
        backgroundColor: '#f9f9f9', // Light background
        borderRadius: '8px',
        fontStyle: 'italic',
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {children}
      </Typography>
    </Box>
  );
};
