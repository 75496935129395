import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import type { CampaignWorkflow } from '@/types/campaign';
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'raviger';
import React from 'react';
import Moment from 'react-moment';

interface CampaignCardProps {
  campaign: CampaignWorkflow;
}

const CampaignCard: React.FC<CampaignCardProps> = ({ campaign }) => {
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const navigate = useNavigate();

  const cardBackground =
    'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)';

  return (
    <Card
      className={currentTheme === 'dark' ? 'custom-border' : ''}
      onClick={() => navigate(`/campaigns/${campaign.id}`)}
      sx={{
        p: 2,
        borderRadius: 4,
        background: cardBackground,
        cursor: 'pointer',
        width: '100%',
        maxWidth: '450px',
        minHeight: '250px',
        '&:hover': {
          scale: 1.01,
        },
        transition: 'scale 0.3s ease-in-out',
      }}
      variant="elevation"
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Chip
          color={
            campaign.status === 'draft'
              ? 'default'
              : campaign.status === 'running'
                ? 'primary'
                : campaign.status === 'not active'
                  ? 'info'
                  : 'success'
          }
          label={campaign.status}
          size="medium"
          sx={{
            textTransform: 'capitalize',
            fontSize: '0.85rem',
            fontWeight: 500,
            width: 'fit-content',
            px: 2,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography
            color="text.primary"
            sx={{ fontWeight: 600, lineHeight: 1.3, fontSize: '1.25rem' }}
            variant="h5"
          >
            {campaign.name}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ fontSize: '0.85rem' }}
            variant="body2"
          >
            <Moment format="MMMM D, YYYY h:mm A">{campaign.created_at}</Moment>
          </Typography>
        </Box>
        <Typography
          color="text.secondary"
          sx={{ mt: 1, fontSize: '0.9rem', lineHeight: 1.6 }}
          variant="body2"
        >
          {campaign.description}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            color="text.primary"
            sx={{ fontWeight: 600, fontSize: '1rem', lineHeight: 1.3 }}
            variant="h6"
          >
            Locations:
          </Typography>
          <AvatarGroup
            max={4}
            sx={{
              '.MuiAvatar-root': { width: 36, height: 36, fontSize: '0.8rem' },
            }}
          >
            {(campaign.locations ?? []).map((location, index) => (
              <Avatar
                alt={location}
                key={index}
                src={`https://flagsapi.com/${location}/flat/64.png`}
              >
                {location}
              </Avatar>
            ))}
          </AvatarGroup>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            mt: 2,
          }}
        >
          {campaign.tags?.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              variant="outlined"
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CampaignCard;
