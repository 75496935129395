import React, { useState, ChangeEvent } from 'react';
import { TextField, Box, Paper } from '@mui/material';

interface TimeInputProps {
  onChange: (time: number) => void;
  initialValues?: TimeValues;
}

interface TimeValues {
  days: number;
  hours: number;
  minutes: number;
}

const TimeInput: React.FC<TimeInputProps> = ({
  onChange,
  initialValues = { days: 0, hours: 0, minutes: 0 },
}) => {
  const [timeValues, setTimeValues] = useState<TimeValues>(initialValues);

  const handleTimeChange = (
    field: keyof TimeValues,
    value: string,
    maxValue: number
  ): void => {
    // Remove non-numeric characters
    const numericValue = value.replace(/[^\d]/g, '');

    // Convert to number and ensure it's within valid range
    const numberValue = Math.min(
      Math.max(parseInt(numericValue) || 0, 0),
      maxValue
    );

    const newValues = {
      ...timeValues,
      [field]: numberValue,
    };

    setTimeValues(newValues);
    const time =
      newValues.days * 24 * 60 * 60 +
      newValues.hours * 60 * 60 +
      newValues.minutes * 60;
    onChange(time);
  };

  const timeFields: Array<{
    field: keyof TimeValues;
    label: string;
    max: number;
  }> = [
    { field: 'days', label: 'Days', max: 365 },
    { field: 'hours', label: 'Hours', max: 23 },
    { field: 'minutes', label: 'Minutes', max: 59 },
  ];

  return (
    <Paper sx={{ p: 1, maxWidth: 'md' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: 2,
        }}
      >
        {timeFields.map(({ field, label, max }) => (
          <TextField
            key={field}
            label={label}
            type="text"
            value={timeValues[field]}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleTimeChange(field, e.target.value, max)
            }
            inputProps={{
              inputMode: 'numeric' as const,
              pattern: '[0-9]*',
              'aria-label': label,
            }}
            variant="outlined"
            fullWidth
            helperText={`Max: ${max}`}
          />
        ))}
      </Box>
    </Paper>
  );
};

export default TimeInput;
