import AugmentComponent from '@/components/Augment';
import CampaignParent from '@/components/Campaigns/CampaignParent';
import { CampaignBuilder } from '@/components/Campaigns/builder';
import CampaignDetails from '@/components/Campaigns/details/CampaignDetails';
import { ChatMainLayout } from '@/components/Converse';
import Dashboard from '@/components/Dashboard/Dashboard';
import AutoEmailLayout from '@/components/Emails/AutoEmailLayout';
import InboxLayout from '@/components/Inbox/InboxLayout';
import InstructionsComponent from '@/components/Instructions/InstructionsComponent';
import Integrations from '@/components/Integrations/Integrations';
import AppLayout from '@/components/layout/AppLayout';
import ProfilePage from '@/components/profile/page';
import SettingsPage from '@/components/settings/SettingPage';
import SignInPage from '@/pages/signIn/page';
import { Redirect, useNavigate } from 'raviger';
import LeadsComponent from '../components/Leads/LeadsComponent';
import Prospector from '../components/Prospector/manual';
import SmartSettingsPage from '../components/Smart-setting/SmartSettingPage';
import PageNotFound from '../pages/not-found/page';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();

  const match = /(^| )accessToken=([^;]+)/.exec(document.cookie);

  if (!match) {
    navigate('/auth');
  }

  return <AppLayout>{children}</AppLayout>;
}

export const routes = {
  '/auth': () => <SignInPage />,
  // '/test': () => <TestActionSelectorBox />,
  '/': () => (
    <ProtectedRoute>
      <Redirect to="/dashboard" />
      {/* <UserButon /> */}
    </ProtectedRoute>
  ),
  '/augment': () => (
    <ProtectedRoute>
      <AugmentComponent />
    </ProtectedRoute>
  ),
  '/campaigns': () => (
    <ProtectedRoute>
      <CampaignParent />
    </ProtectedRoute>
  ),
  '/campaigns/builder': () => (
    <ProtectedRoute>
      <CampaignBuilder />
    </ProtectedRoute>
  ),
  '/campaigns/:id': ({ id }: { id: string }) => (
    <ProtectedRoute>
      <CampaignDetails campaignId={id} />
    </ProtectedRoute>
  ),
  '/inbox': () => (
    <ProtectedRoute>
      <InboxLayout />
    </ProtectedRoute>
  ),
  '/automated-emails': () => (
    <ProtectedRoute>
      <AutoEmailLayout />
    </ProtectedRoute>
  ),
  '/settings': () => (
    <ProtectedRoute>
      <SettingsPage />
    </ProtectedRoute>
  ),
  '/converse': () => (
    <ProtectedRoute>
      <ChatMainLayout />
    </ProtectedRoute>
  ),
  '/dashboard': () => (
    <ProtectedRoute>
      <Dashboard />
    </ProtectedRoute>
  ),
  '/integrations': () => (
    <ProtectedRoute>
      <Integrations />
    </ProtectedRoute>
  ),
  '/prospector': () => (
    <ProtectedRoute>
      <Prospector />
    </ProtectedRoute>
  ),
  '/leads': () => (
    <ProtectedRoute>
      <LeadsComponent />
    </ProtectedRoute>
  ),
  '/smart-setting': () => (
    <ProtectedRoute>
      <SmartSettingsPage />
    </ProtectedRoute>
  ),
  // '/workx': () => (
  //   <ProtectedRoute>
  //     <Work />
  //   </ProtectedRoute>
  // ),
  '/instructions': () => (
    <ProtectedRoute>
      <InstructionsComponent />
    </ProtectedRoute>
  ),
  '/profile': () => (
    <ProtectedRoute>
      <ProfilePage />
    </ProtectedRoute>
  ),
  '*': () => <PageNotFound />,
};
