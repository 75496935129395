import { ModalContent, StyledModal } from '@/constants/themes';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface DeleteFieldModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteFieldModal: React.FC<DeleteFieldModalProps> = ({
  open,
  onClose,
  onDelete,
}) => (
  <StyledModal onClose={onClose} open={open}>
    <ModalContent
      sx={{
        maxWidth: '30vw',
        backgroundColor: 'background.paper',
      }}
    >
      <DialogTitle>Delete Field</DialogTitle>
      <DialogContent>Are you sure you want to delete this field?</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </ModalContent>
  </StyledModal>
);

export default DeleteFieldModal;
