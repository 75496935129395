import useTemplateBuilderStore from '@/hooks/useTemplateBuilderStore';
import { CampaignWorkflowTemplate } from '@/types/campaign';
import { Field } from '@/types/campaign/field';
import {
  CustomSelect,
  CustomSwitch,
  CustomTextArea,
  Features,
  ListOfTextInputs,
  MinMaxRange,
} from '@/ui';
import { Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteFieldModal from './DeleteFieldModal';

interface FieldItemProps {
  field: Field;
  index: number;
  fields: Field[];
  setFields: React.Dispatch<React.SetStateAction<Field[]>>;
  activeStepIndex: number;
  onUpdateTemplate: (template: CampaignWorkflowTemplate) => void;
}

const FieldItem: React.FC<FieldItemProps> = ({
  field,
  index,
  fields,
  setFields,
  activeStepIndex,
  onUpdateTemplate,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [updatedField, setUpdatedField] = useState<Field>(field);
  const [optionText, setOptionText] = useState<string>('');

  const currentCampaignTemplate = useTemplateBuilderStore(
    (store) => store.currentCampaignTemplate
  );

  // Sync updatedField with the field prop when it changes
  useEffect(() => {
    setUpdatedField(field);
  }, [field]);

  const handleDeleteField = () => {
    if (currentCampaignTemplate === null) return;
    const updatedFields = fields.filter((_, i) => i !== index);

    setFields(updatedFields);

    const updatedCampaign = { ...currentCampaignTemplate };
    updatedCampaign.steps[activeStepIndex].fields = updatedFields;
    onUpdateTemplate(updatedCampaign);
    setDeleteModalOpen(false);
  };

  const handleConfirmEdit = () => {
    if (currentCampaignTemplate === null) return;
    const updatedFields = [...fields];
    updatedFields[index] = updatedField;

    setFields(updatedFields);

    const updatedCampaign = { ...currentCampaignTemplate };
    updatedCampaign.steps[activeStepIndex].fields = updatedFields;
    onUpdateTemplate(updatedCampaign);
    setEdit(false);
  };

  const handleUpdateName = (name: string) => {
    setUpdatedField((prev) => ({ ...prev, name }));
  };

  const handleAddOption = (option: string) => {
    if (field.field_type === 'input.select') {
      const newOptions = [
        // @ts-ignore
        ...(updatedField.options || []),
        { option_name: option, value: option },
      ];
      setUpdatedField((prev) => ({ ...prev, options: newOptions }));
    }
  };

  const renderField = () => {
    switch (field.field_type) {
      case 'input.select':
        return (
          <CustomSelect
            name={updatedField.name}
            large_options={false}
            multiselect={field.multiselect || false}
            placeholder={field.placeholder}
            // @ts-ignore
            options={updatedField.options || []}
            value=""
            onChange={() => {}}
          />
        );
      case 'input.switch':
        return (
          <CustomSwitch
            name={updatedField.name}
            value={false}
            onChange={() => {}}
          />
        );
      case 'input.textarea':
        return (
          <CustomTextArea
            name={updatedField.name}
            value=""
            onChange={() => {}}
            multiline
            placeholder={field.placeholder}
          />
        );
      case 'min_max_range':
        return (
          <MinMaxRange
            name={updatedField.name}
            value={{ min: 0, max: 0 }}
            onChange={() => {}}
            max_placeholder={field.max_placeholder}
            min_placeholder={field.min_placeholder}
          />
        );
      case 'list_of_text_inputs':
        return (
          <ListOfTextInputs
            name={updatedField.name}
            value={['']}
            onChange={() => {}}
          />
        );
      case 'list_of_features':
        return (
          <Features
            name={updatedField.name}
            value={[{ painpoint: '', solution: '' }]}
            onChange={() => {}}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <Box sx={{ flexGrow: 1 }}>
        {isEdit && (
          <TextField
            value={updatedField.name}
            label="Field Name"
            variant="outlined"
            fullWidth
            onChange={(e) => handleUpdateName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        )}
        {renderField()}
        {isEdit && field.field_type === 'input.select' && (
          <TextField
            label="Add Option"
            variant="outlined"
            placeholder='Press "Enter" to add option'
            fullWidth
            value={optionText}
            onChange={(e) => setOptionText(e.target.value)}
            sx={{ marginTop: 2 }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddOption(optionText);
                setOptionText('');
              }
            }}
          />
        )}
      </Box>
      <IconButton
        color="primary"
        onClick={() => {
          if (isEdit) {
            handleConfirmEdit();
          }
          setEdit(!isEdit);
        }}
      >
        {isEdit ? <Save /> : <EditIcon />}
      </IconButton>
      <IconButton color="error" onClick={() => setDeleteModalOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <DeleteFieldModal
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleDeleteField}
      />
    </Box>
  );
};

export default FieldItem;
