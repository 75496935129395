import { templates as campaign_templates } from '@/constants/campaigns/template';
import type {
  CampaignWorkflow,
  CampaignWorkflowTemplate,
  Step,
} from '@/types/campaign';
import YAML from 'yaml';
import { fetchWithRewrites } from '../fetchWithRewrites';

export const dummyIds: string[] = ['c72c6b18-8a2f-423d-b273-49d6b86efcfa'];

export const fetchCampaigns = async (): Promise<CampaignWorkflow[]> => {
  // TODO: Fetch campaigns API
  const response = await fetchWithRewrites(`/api/campaigns/list`);

  const responseJson: { campaigns: CampaignWorkflow[] } = await response.json();
  console.log('Campaigns found = ', responseJson.campaigns.length);
  console.log(responseJson.campaigns);

  return responseJson.campaigns;
};

export const fetchTemplates = async (
  localStorageTemplates: {
    name: string;
    description: string;
    yaml: string;
    category: string;
  }[] = []
): Promise<CampaignWorkflowTemplate[]> => {
  const templates: CampaignWorkflowTemplate[] = [];
  [...campaign_templates, ...localStorageTemplates].forEach((template) => {
    const parsedYaml = YAML.parse(template.yaml).steps as Step[];
    templates.push({
      steps: parsedYaml,
      name: template.name,
      description: template.description,
      category: template.category,
    });
  });
  return templates;
};
