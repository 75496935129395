import { ModalContent, StyledButton, StyledModal } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { CampaignWorkflow, CampaignWorkflowTemplate } from '@/types/campaign';
import { fetchTemplates } from '@/utils/campaigns/fetchCampaigns';
import { Adjust, Create } from '@mui/icons-material';
import {
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { navigate } from 'raviger';
import React, { useEffect, useState } from 'react';
import GenerateWorkflowPromptBox from './GenerateWorkflowPromptBox';
import Sidebar from './Sidebar';
import StartWithTemplateForm from './StartWithTemplateForm';
import useTemplateBuilderStore from '@/hooks/useTemplateBuilderStore';
import empty from '@/assets/empty.png';
import YAML from 'yaml';

interface AddCampaignModalProps {
  open: boolean;
  onClose: () => void;
}

function AddCampaignModal({
  open,
  onClose,
}: AddCampaignModalProps): React.ReactNode {
  const [activeIndustry, setActiveIndustry] = useState<string>('For you');
  const [templates, setTemplates] = useState<{
    [key: string]: CampaignWorkflowTemplate[];
  }>({});
  const [selectedTemplate, setSelectedTemplate] =
    useState<CampaignWorkflowTemplate | null>(null);

  const [campaignName, setCampaignName] = useState<string>('');
  const [campaignDescription, setCampaignDescription] = useState<string>('');
  const [locations, setLocations] = useState<
    {
      name: string;
      code: string;
    }[]
  >([]);
  const [tags, setTags] = useState<string[]>([]);

  const selectedLeads = useAlchemystStoreForAi((state) => state.selectedLeads);
  const currentTheme = useAlchemystStoreForAi((state) => state.theme);
  const campaignWorkflows = useAlchemystStoreForAi(
    (state) => state.campaignWorkflows
  );
  const setStoreState = useAlchemystStoreForAi((state) => state.setStoreState);

  useEffect(() => {
    async function fetchTemplatesData() {
      const localStorageTemplatesString =
        localStorage.getItem('campaignTemplates');
      const localStorageTemplates = localStorageTemplatesString
        ? JSON.parse(localStorageTemplatesString)
        : [];
      const templates = await fetchTemplates(localStorageTemplates);
      const templatesMap: { [key: string]: CampaignWorkflowTemplate[] } = {};
      templates.forEach((template) => {
        const category = template.category || 'For you';
        if (!templatesMap[category]) {
          templatesMap[category] = [];
        }
        templatesMap[category].push(template);
      });
      setTemplates(templatesMap);
    }
    fetchTemplatesData();
  }, []);

  const handleCreateCampaign = () => {
    const campaignData: CampaignWorkflow = {
      id: 'new',
      name: campaignName,
      description: campaignDescription,
      locations: locations.map((location) => location.code),
      active: false,
      status: 'draft',
      leads: selectedLeads.map((lead) => ({
        ...lead,
        status: 'no action',
        progress: 0,
      })),
      steps: selectedTemplate?.steps || [],
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      tags: tags,
      dashboard: {
        'Warmup Emails Sent Today': selectedLeads.length,
        'Followup Emails Sent Today': selectedLeads.length - 1,
        'Emails Received Today': 1,
        'Conversion Percentage': Math.ceil(100 / selectedLeads.length),
      },
      stepConfig: [],
      metadata: {
        actions: [],
      },
    };
    setStoreState({
      campaignWorkflows: [...campaignWorkflows, campaignData],
    });
    setTimeout(() => {
      onClose();
      navigate(`/campaigns/${campaignData.id}`);
    }, 500);
  };

  const handleCustomizeTemplate = (template: CampaignWorkflowTemplate) => {
    const yaml = YAML.stringify(template);
    localStorage.setItem('generatedCampaignTemplate', yaml);
    navigate('/campaigns/builder');
  };

  const cardBackground =
    'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)';

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="add-campaign-modal-title"
      aria-describedby="add-campaign-modal-description"
    >
      <ModalContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '70vw',
          height: '80vh',
          backgroundColor: 'background.paper',
          boxShadow: 3,
          borderRadius: 3,
          overflow: 'hidden',
          padding: 4,
        }}
      >
        <Sidebar
          activeIndustry={activeIndustry}
          setActiveIndustry={setActiveIndustry}
        />
        {selectedTemplate ? (
          <StartWithTemplateForm
            name={selectedTemplate.name}
            campaignDescription={campaignDescription}
            locations={locations}
            campaignName={campaignName}
            tags={tags}
            setCampaignName={setCampaignName}
            setCampaignDescription={setCampaignDescription}
            setLocations={setLocations}
            setSelectedTemplate={setSelectedTemplate}
            onCreateCampaign={handleCreateCampaign}
            setTags={setTags}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: '100%',
              height: '100%',
            }}
          >
            <GenerateWorkflowPromptBox />

            <Divider
              sx={{
                color: 'gray',
              }}
            >
              or
            </Divider>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Choose a template
            </Typography>
            {!templates[activeIndustry] && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                  gap: 2,
                }}
              >
                <img width={150} height={150} src={empty} alt="No templates" />
                <Typography variant="h6" color="text.secondary">
                  No templates available for this industry
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                width: '100%',
                maxHeight: '70vh',
                overflowY: 'scroll',
                paddingRight: 2,
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
                gap: 2,
              }}
            >
              {templates[activeIndustry]?.map((template, index) => (
                <Card
                  variant="elevation"
                  key={index}
                  sx={{
                    p: 2,
                    borderRadius: 4,
                    background: cardBackground,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    gap: 2,
                    height: '27vh',
                  }}
                  className={currentTheme === 'dark' ? 'custom-border' : ''}
                >
                  <Stepper activeStep={template.steps.length} alternativeLabel>
                    {template.steps.map((_, index) => (
                      <Step key={index}>
                        <StepLabel icon={<Adjust color="disabled" />} />
                      </Step>
                    ))}
                  </Stepper>

                  <Typography variant="h5">
                    {template.name.substring(0, 25)}
                    {template.name.length > 25 ? '...' : ''}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {template.description.substring(0, 100)}
                    {template.description.length > 100 ? '...' : ''}
                  </Typography>
                  <Box display={'flex'}>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={`${template.steps.length} steps`}
                      disabled
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={`${template.steps.reduce(
                        (acc, step) => acc + (step.fields?.length ?? 0),
                        0
                      )} Total fields`}
                      disabled
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                      gap: 2,
                    }}
                  >
                    <StyledButton
                      variant="outlined"
                      size="small"
                      sx={{
                        padding: '5px 20px',
                        fontSize: '14px',
                        borderRadius: '8px',
                        width: '90%',
                      }}
                      onClick={() => setSelectedTemplate(template)}
                    >
                      Use Template
                    </StyledButton>
                    <StyledButton
                      variant="outlined"
                      size="small"
                      sx={{
                        padding: '5px 20px',
                        fontSize: '14px',
                        borderRadius: '8px',
                        width: '10%',
                      }}
                      onClick={() => {
                        handleCustomizeTemplate(template);
                      }}
                    >
                      <Create />
                    </StyledButton>
                  </Box>
                </Card>
              ))}
            </Box>
          </Box>
        )}
      </ModalContent>
    </StyledModal>
  );
}

export default AddCampaignModal;
