import { Header, StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { CampaignWorkflow } from '@/types/campaign';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import {
  CheckCircle,
  Circle,
  DirectionsRun,
  Edit,
  ToggleOff,
} from '@mui/icons-material';
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardContent,
  Chip,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';

interface CampaignDashboardProps {
  campaign: CampaignWorkflow;
  senderName: string;
  onStatusChange: (status: CampaignWorkflow['status']) => void;
  onSenderNameChange: (sender: string) => void;
}

const getChipColor = (
  status: 'draft' | 'running' | 'not active' | 'completed'
) => {
  switch (status) {
    case 'running':
      return 'primary';
    case 'not active':
      return 'info';
    case 'completed':
      return 'success';
    case 'draft':
      return 'inherit';
  }
};

const getChipIcon = (status: string) => {
  switch (status) {
    case 'running':
      return <DirectionsRun sx={{ fontSize: 35 }} />;
    case 'draft':
      return <Edit sx={{ fontSize: 35 }} />;
    case 'not active':
      return <ToggleOff sx={{ fontSize: 35 }} />;
    case 'completed':
      return <CheckCircle sx={{ fontSize: 35 }} />;
    default:
      return <Circle sx={{ fontSize: 35 }} />;
  }
};

const StatusModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onStatusChange: (status: CampaignWorkflow['status']) => void;
  currentStatus: string;
}> = ({ open, onClose, onStatusChange, currentStatus }) => {
  const statuses: CampaignWorkflow['status'][] = [
    'draft',
    'running',
    'not active',
    'completed',
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Change Campaign Status
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {statuses.map((status) => (
            <StyledButton
              key={status}
              variant={status === currentStatus ? 'contained' : 'outlined'}
              color={getChipColor(status)}
              onClick={() => {
                onStatusChange(status);
                onClose();
              }}
            >
              {status}
            </StyledButton>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

const CampaignDashboard: React.FC<CampaignDashboardProps> = ({
  campaign,
  senderName,
  onSenderNameChange,
  onStatusChange,
}) => {
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  console.log('Campaign data: ', campaign);
  
  return (
    <Box sx={{ p: 6 }}>
      <Header>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography
              color="text.primary"
              sx={{ fontWeight: 600, lineHeight: 1.3 }}
              variant="h4"
            >
              {campaign.name}
            </Typography>
            <Typography color="text.secondary" variant="body1">
              <Moment format="MMMM D, YYYY h:mm A">
                {campaign.created_at}
              </Moment>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Chip
            color={
              getChipColor(campaign.status) === 'inherit'
                ? 'default'
                : (getChipColor(campaign.status) as
                    | 'primary'
                    | 'info'
                    | 'success'
                    | 'default'
                    | 'secondary'
                    | 'error'
                    | 'warning')
            }
            label={campaign.status}
            size="medium"
            sx={{
              textTransform: 'capitalize',
              fontSize: '1.2rem',
              fontWeight: 500,
              width: 'fit-content',
              p: 3,
              cursor: 'pointer',
            }}
            icon={getChipIcon(campaign.status)}
            onClick={() => setModalOpen(true)}
          />
        </Box>
      </Header>

      <Box
        sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Typography
          color="text.primary"
          sx={{ fontWeight: 600, fontSize: '1rem', lineHeight: 1.3 }}
          variant="h6"
        >
          Locations:
        </Typography>
        <AvatarGroup
          max={4}
          sx={{
            '.MuiAvatar-root': { width: 36, height: 36, fontSize: '0.8rem' },
          }}
        >
          {(campaign.locations ?? []).map((location, index) => (
            <Avatar
              alt={location}
              key={index}
              src={`https://flagsapi.com/${location}/flat/64.png`}
            >
              {location}
            </Avatar>
          ))}
        </AvatarGroup>
      </Box>
      <TextField
        value={senderName}
        onChange={(e) => onSenderNameChange(e.target.value)}
        label="Send as"
      />
      <Typography
        color="text.secondary"
        sx={{ mt: 1, fontSize: '0.9rem', lineHeight: 1.6 }}
        variant="body2"
      >
        {campaign.description}
      </Typography>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
        {campaign.tags?.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            variant="outlined"
            sx={{ fontSize: '0.8rem', fontWeight: 500 }}
          />
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          mt: 3,
        }}
      >
        {Object.entries(campaign.dashboard).map(([key, value], index) => (
          <Card
            variant="elevation"
            key={index}
            sx={{
              padding: 1,
              borderRadius: 4,
              width: '100%',
              background:
                'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
            }}
            className={
              currentTheme === 'dark'
                ? 'custom-border'
                : 'custom-border_for_light'
            }
          >
            <CardContent>
              <Typography variant="h3">
                {value}
                {key === 'Conversion Percentage' && '%'}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {key}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Box sx={{ my: 2 }}>
        <Typography variant="h5" gutterBottom>
          {campaign.leads.length} Leads
        </Typography>
        <DataGridPro
          sx={{ width: '99%', fontSize: '1.15rem' }}
          density="comfortable"
          rows={campaign.leads}
          columns={[
            { field: 'id', headerName: 'ID', flex: 0.25 },
            { field: 'name', headerName: 'Name', flex: 0.8 },
            { field: 'title', headerName: 'Title', flex: 1 },
            {
              field: 'email',
              headerName: 'Email',
              flex: 1.1,
              renderCell: (params) => (
                <a
                  href={`mailto:${params.value}`}
                  className="underline text-blue-200"
                >
                  {params.value}
                </a>
              ),
            },
            {
              field: 'progress',
              headerName: 'Progress',
              flex: 0.4,
              renderCell: (params) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    gap: 1,
                  }}
                >
                  {[1, 2, 3].map((step) => (
                    <Box
                      key={step}
                      sx={{
                        height: 25,
                        width: 25,
                        backgroundColor:
                          params.value >= step
                            ? step === 3 && params.value >= 4
                              ? 'red'
                              : step === 3
                                ? 'green'
                                : step === 2
                                  ? 'yellow'
                                  : 'blue'
                            : '',
                        border: `1px solid ${params.value >= step ? (step === 3 && params.value >= 4 ? 'red' : step === 3 ? 'green' : step === 2 ? 'yellow' : 'blue') : 'gray'}`,
                      }}
                    />
                  ))}
                </Box>
              ),
            },
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              renderCell: (params) => (
                <Chip
                  label={params.value}
                  variant="outlined"
                  color={(() => {
                    switch (params.value) {
                      case 'no action':
                        return 'default';
                      case 'Warmup email sent':
                        return 'info';
                      case 'Followup email sent':
                        return 'warning';
                      case 'Lead not interested':
                        return 'error';
                      case 'Lead started conversation':
                        return 'success';
                      default:
                        return 'info';
                    }
                  })()}
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '0.85rem',
                    fontWeight: 500,
                    px: 2,
                  }}
                />
              ),
            },
          ]}
          columnHeaderHeight={40}
          hideFooter
          rowSelection={false}
        />
      </Box>
      <StatusModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onStatusChange={onStatusChange}
        currentStatus={campaign.status}
      />
    </Box>
  );
};

export default CampaignDashboard;
