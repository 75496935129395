import { Step } from '@/types/campaign';
import { Box } from '@mui/material';
import React from 'react';
import CustomStep from './CustomStep';
import CustomStepperConnector from './CustomStepperConnector';
import EditStepModal from './EditStepModal';

interface CustomStepperProps {
  activeStep: number;
  steps: Step[];
  onStepChange: (step: number) => void;
  onAddStep: (leftIndex: number, step_name: string) => void;
  onRemoveStep: (index: number) => void;
  onEditStep: (index: number, value: string) => void;
}

const CustomStepper: React.FC<CustomStepperProps> = ({
  activeStep,
  steps,
  onStepChange,
  onAddStep,
  onRemoveStep,
  onEditStep,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: 2,
      }}
    >
      <CustomStepperConnector leftIndex={-1} onAddStep={onAddStep} />

      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <CustomStep
            index={index}
            activeStep={activeStep}
            value={step}
            onChange={() => onStepChange(index)}
            onEdit={(value) => onEditStep(index, value)}
            onRemove={() => onRemoveStep(index)}
          />
          <CustomStepperConnector leftIndex={index} onAddStep={onAddStep} />
        </React.Fragment>
      ))}

      <EditStepModal
        open={false}
        onClose={() => {}}
        onSubmit={(name) => onEditStep(0, name)} // Replace with actual handling
      />
    </Box>
  );
};

export default CustomStepper;
