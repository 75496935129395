import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import type { CampaignWorkflow } from '@/types/campaign';

import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import { fetchCampaigns } from '@/utils/campaigns/fetchCampaigns';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { Box, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CampaignDashboard from './CampaignDashboard';
import CampaignForm from './CampaignForm';

interface CampaignDetailsProps {
  campaignId: string;
}

type EmailItem = {
  _id: string;
  emailId: string;
};

const CampaignDetails: React.FC<CampaignDetailsProps> = ({ campaignId }) => {
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const alchemystPersona = useAlchemystStoreForAi((store) => store.alchemyst);
  const [senderName, setSenderName] = useState<string>(alchemystPersona.name);
  const campaignWorkflows = useAlchemystStoreForAi(
    (store) => store.campaignWorkflows
  );
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const inboxEmails = useAlchemystStoreForAi((store) => store.inboxEmails);

  const campaignData = useCampaignBuilderStore((store) => store.campaignData);
  const setBuilderStoreState = useCampaignBuilderStore(
    (store) => store.setStoreState
  );

  console.log('campaignWorkflows:', campaignWorkflows);

  // Fetch campaign workflows with error handling
  // Effect to fetch campaign when not found in store
  const fetchCampaignWorkflows = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const _campaignWorkflows = await fetchCampaigns();
      setStoreState({
        campaignWorkflows: _campaignWorkflows,
      });
      setBuilderStoreState({
        campaignData:
          _campaignWorkflows.find((c) => c.id === campaignId) || null,
      });
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  }, [campaignId, setStoreState]);

  useEffect(() => {
    const foundCampaign = campaignWorkflows.find((c) => c.id === campaignId);
    if (foundCampaign) {
      setBuilderStoreState({ campaignData: foundCampaign });
    } else {
      void fetchCampaignWorkflows();
    }
  }, [campaignId]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetchWithRewrites(
          '/api/inbox/list?emailsOnly=true',
          {
            signal: createTimeoutSignal(),
          }
        );
        if (!response.ok) throw new Error('Failed to fetch emails');
        const emails = await response.json();
        const inboxEmailsList = emails.data.map(
          (item: EmailItem) => item.emailId
        );
        setStoreState({
          inboxEmails: inboxEmailsList,
        });

        // console.log(emails.data[0]);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateCampaign = (updatedCampaign: CampaignWorkflow) => {
    const updatedCampaigns = campaignWorkflows.map((workflow) =>
      workflow.id === updatedCampaign.id ? updatedCampaign : workflow
    );
    setStoreState({ campaignWorkflows: updatedCampaigns });
  };

  const handleChangeStatus = async (status: CampaignWorkflow['status']) => {
    if (inboxEmails.length === 0) {
      throw new Error('No inbox emails found');
    } else {
      console.log('inboxEmails:', inboxEmails);
    }
    //collect data

    // campaign?.stepConfig.forEach(async (step) => {
    //   const metaData = {
    //     action: step.actions,
    //   };
    //   const campaignData = {
    //     leads: selectedLeads,
    //     fromEmails: inboxEmails,
    //     body: step.message.body,
    //     subject: step.message.subject,
    //     waitTime: step.waitTime,
    //     senderName,
    //   };
    //   // send api request
    //   const response = await fetchWithRewrites('/api/campaigns/update', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ campaignData, metaData }),
    //   });
    //   if (!response.ok) {
    //     throw new Error('Failed to start campaign');
    //   }
    // });

    if (!inboxEmails) {
      console.log('No inbox emails found');
      return;
    }

    console.log('Sender Name:', senderName);

    const updatedFromEmails = inboxEmails.map((email, index) => {
      return {
        email: email,
        number: index,
        username: senderName,
        fullName: senderName,
      };
    });

    console.log('Updated from emails:', updatedFromEmails);
    const response = await fetchWithRewrites('/api/campaigns/update', {
      method: 'POST',
      body: JSON.stringify({
        ...campaignData,
        status: status,
        stepConfig: campaignData?.stepConfig.map((step) => {
          return {
            ...step,
            from: updatedFromEmails,
          };
        }),
      }),
    });
    if (!response.ok) {
      console.error('Failed to start campaign');
    }

    if (campaignData) {
      setBuilderStoreState({
        campaignData: {
          ...campaignData,
          status,
        },
      });
    }
  };

  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!campaignData) {
    return <div>Campaign not found</div>;
  }

  if (campaignData.status === 'draft') {
    return <CampaignForm />;
  }
  return (
    <CampaignDashboard
      onSenderNameChange={setSenderName}
      senderName={senderName}
      campaign={campaignData}
      onStatusChange={handleChangeStatus}
    />
  );
};

export default CampaignDetails;
