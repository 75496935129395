import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect } from 'react';

function ActionSelectorBox({
  actions,
  allActions,
  setActions,
  stepConfigIdx: idx,
}: {
  actions: string[];
  allActions: string[];
  setActions: (actions: string | string[], idx?: number) => void;
  stepConfigIdx?: number;
}) {
  // console.log('Selected actions=', actions);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const selectedValues =
      typeof value === 'string' ? value.split(',') : value || [];
    console.log('Selected Values = ');
    console.log(selectedValues);
    setActions(selectedValues, idx);
  };

  return (
    <Select multiple value={actions || []} onChange={handleChange}>
      {allActions.map((action, idx) => (
        <MenuItem
          value={action}
          key={`action-${idx + 1}-${action}`}
          // disabled={action.toLowerCase() !== 'email'}
        >
          {action.toUpperCase()}
        </MenuItem>
      ))}
    </Select>
  );
}

export default ActionSelectorBox;
