import { EMAIL_CREDENTIALS } from '@/constants/emailConfig';
import { fetchWithRewrites } from './fetchWithRewrites';
import { createTimeoutSignal } from './signalConfig';

/**
 * Sets IMAP credentials by making a POST request to the API.
 *
 * @param imapEmail - The IMAP email address.
 * @param imapPassword - The IMAP password.
 * @param meetingLink - The meeting link.
 * @returns `Promise<boolean>` - Returns true if credentials were set successfully, false otherwise.
 */
export const setImapCredentials = async (
  imapEmail: string,
  imapPassword: string,
  meetingLink: string,
  provider = 'gmail',
  providerIMAPHost = 'imap.gmail.com',
  providerIMAPPort = 993,
  providerSMTPHost = 'smtp.gmal.com',
  providerSMTPPort = 587,
  providerInboxName = 'INBOX',
  providerSentBoxName = '[Gmail]/Sent Mail'
): Promise<boolean> => {
  try {
    const formatImapPassword = imapPassword.replace(/\s+/g, '');
    const response = await fetchWithRewrites('/api/inbox/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: imapEmail,
        password: formatImapPassword,
        meetingLink,
        provider,
        providerIMAPHost: Object.keys(EMAIL_CREDENTIALS).includes(provider)
          ? EMAIL_CREDENTIALS[provider].providerIMAPHost
          : providerIMAPHost,
        providerIMAPPort: Object.keys(EMAIL_CREDENTIALS).includes(provider)
          ? EMAIL_CREDENTIALS[provider].providerIMAPPort
          : providerIMAPPort,
        providerSMTPHost: Object.keys(EMAIL_CREDENTIALS).includes(provider)
          ? EMAIL_CREDENTIALS[provider].providerSMTPHost
          : providerSMTPHost,
        providerSMTPPort: Object.keys(EMAIL_CREDENTIALS).includes(provider)
          ? EMAIL_CREDENTIALS[provider].providerSMTPPort
          : providerSMTPPort,
        providerInboxName: Object.keys(EMAIL_CREDENTIALS).includes(provider)
          ? EMAIL_CREDENTIALS[provider].providerInboxName
          : providerInboxName,
        providerSentBoxName: Object.keys(EMAIL_CREDENTIALS).includes(provider)
          ? EMAIL_CREDENTIALS[provider].providerSentBoxName
          : providerSentBoxName,
      }),
      signal: createTimeoutSignal(),
    });

    if (!response.ok) {
      console.error(`Error: ${response.statusText}`);
      return false;
    }

    return response.status === 201;
  } catch (error) {
    console.error('Failed to set IMAP credentials:', error);
    return false;
  }
};
