import { CampaignWorkflow } from '@/types/campaign';
import { create } from 'zustand';

interface CampaignBuilderStoreType {
  campaignData: CampaignWorkflow | null;
  metadata: Record<string, any> & { actions: string[] };
  setStoreState: (newState: Partial<CampaignBuilderStoreType>) => void;
}

/**
 * Type guard for checking if a value is a non-null object
 */
const isObject = (value: unknown): value is Record<string, unknown> => {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    !(value instanceof Date)
  );
};

/**
 * Type guard for checking if a value is an array
 */
const isArray = (value: unknown): value is unknown[] => {
  return Array.isArray(value);
};

/**
 * Type guard for checking if a value is a Date
 */
const isDate = (value: unknown): value is Date => {
  return value instanceof Date;
};

/**
 * Types for the deep merge functionality
 */
type DeepPartial<T> =
  T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends Date
      ? T | undefined
      : T extends object
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : T | undefined;

/**
 * Handles merging of array values
 */
function mergeArrays<T>(target: T[], source: DeepPartial<T>[]): T[] {
  // @ts-ignore
  return source.map((sourceItem, index) => {
    const targetItem = index < target.length ? target[index] : undefined;

    if (sourceItem === null || targetItem === undefined) {
      return sourceItem as T;
    }

    if (isObject(sourceItem) && isObject(targetItem)) {
      // @ts-ignore
      return mergeObjects(targetItem, sourceItem);
    }

    if (isArray(sourceItem) && isArray(targetItem)) {
      return mergeArrays(targetItem, sourceItem);
    }

    return sourceItem as T;
  });
}

/**
 * Handles merging of object values
 */
function mergeObjects<T extends Record<string, unknown>>(
  target: T,
  source: DeepPartial<T>
): T {
  const result = { ...target };

  for (const [key, sourceValue] of Object.entries(source)) {
    if (!Object.prototype.hasOwnProperty.call(source, key)) {
      continue;
    }

    const targetValue = target[key];

    // Skip undefined values
    if (sourceValue === undefined) {
      continue;
    }

    // Handle null values
    if (sourceValue === null) {
      // @ts-ignore
      result[key] = null as any;
      continue;
    }

    // Handle dates
    if (isDate(sourceValue) && isDate(targetValue)) {
      // @ts-ignore
      result[key] = new Date(sourceValue) as any;
      continue;
    }

    // Handle arrays
    if (isArray(sourceValue) && isArray(targetValue)) {
      // @ts-ignore
      result[key] = mergeArrays(targetValue, sourceValue) as any;
      continue;
    }

    // Handle nested objects
    if (isObject(sourceValue) && isObject(targetValue)) {
      // @ts-ignore
      result[key] = mergeObjects(targetValue, sourceValue) as any;
      continue;
    }

    // Handle primitive values
    // @ts-ignore
    result[key] = sourceValue as any;
  }

  return result;
}

/**
 * Merges a partial object into a target object, handling nested objects, arrays, and dates.
 *
 * @param target - The target object to merge into
 * @param source - The partial source object to merge from
 * @returns A new object with the merged properties
 *
 * @example
 * const target = {
 *   name: "John",
 *   settings: { theme: "dark", notifications: true },
 *   tags: ["important"]
 * };
 *
 * const source = {
 *   settings: { theme: "light" },
 *   tags: ["new-tag"]
 * };
 *
 * const result = mergePartialObject(target, source);
 */
function mergePartialObject<T>(target: T, source: DeepPartial<T>): T {
  // Handle null or undefined source
  if (!source || typeof source !== 'object') {
    return target;
  }

  // Handle dates
  if (isDate(target) && isDate(source)) {
    return new Date(source) as T;
  }

  // Handle arrays
  if (isArray(target) && isArray(source)) {
    return mergeArrays(target, source) as T;
  }

  // Handle objects
  if (isObject(target) && isObject(source)) {
    // @ts-ignore
    return mergeObjects(target, source) as T;
  }

  // Handle primitives
  return (source as T) ?? target;
}

const useCampaignBuilderStore = create<CampaignBuilderStoreType>((set) => ({
  // Initial state setup
  campaignData: null,
  metadata: { actions: [] },
  // Generic setState method
  setStoreState: (newState: Partial<CampaignBuilderStoreType>) => {
    set((state) =>
      mergePartialObject<CampaignBuilderStoreType>(state, newState)
    );
  },
}));

export default useCampaignBuilderStore;
