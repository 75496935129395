export const templates = [
  {
    name: 'Alchemyst AI Sales Campaign',
    description:
      'This workflow is designed to aid in development of a sales campaign.',
    category: 'For you',
    yaml: `
steps:
  - step_name: Pitch
    description: Gather necessary information about the target clients.
    fields:
      - field_type: input.textarea
        name: "Url explaining"
        placeholder: "yourWebsite"
        control_var: websiteUrlField
        multiple: BOOLEAN

      - field_type: input.textarea
        name: "One sentence offering description"
        placeholder: "My Company is great"
        control_var: companyOffering
        multiple: BOOLEAN

      - field_type: list_of_features
        name: "Features"
        control_var: features 

      - field_type: list_of_text_inputs
        name: Proof of points
        control_var: proofOfPoints

      - field_type: input.select
        name: "Tone of voice"
        placeholder: "Please select the tone of voice"
        options:
          - option_name: Professional
            value: professional
          - option_name: Friendly
            value: friendly
          - option_name: Solution-oriented
            value: solutionOriented
        control_var: toneOfVoice 

      - field_type: list_of_text_inputs
        name: "Call to action"
        control_var: callToAction

      - field_type: input.textarea
        name: "Agenda"
        placeholder: "List the agenda for the outreach"
        control_var: agenda
        multiple: BOOLEAN

      - field_type: input.textarea
        name: "Narrative"
        placeholder: "Provide a narrative to engage clients"
        control_var: narrative
        multiple: BOOLEAN
`,
  },
];
