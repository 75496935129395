import { TextField } from '@mui/material';
import React from 'react';
import type { CustomTextAreaProps } from './types';

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  name,
  placeholder,
  multiline = false,
  value = '',
  onChange,
  sx,
}) => {
  return (
    <TextField
      fullWidth
      label={name}
      minRows={4}
      multiline={multiline}
      name={name}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={placeholder}
      sx={sx}
      value={value}
    />
  );
};

export default CustomTextArea;
