import TimeInput from '@/components/common/DateTimePicker';
import { IOSSwitch, StyledButton } from '@/constants/themes';
import { StepConfig } from '@/types/campaign';
import {
  Box,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
  dividerClasses,
} from '@mui/material';
import { RichTextEditor } from 'mui-tiptap';
import EditorMenuControls, { InsertTag } from './EditorMenuControls';
import useExtensions from './useExtensions';

const selectTagsFromLeads = (selectedLeads: Record<string, any>[]) => {
  return selectedLeads.length > 0 ? Object.keys(selectedLeads[0]) : [];
};

export interface StepsEditorProps {
  selectedLeads: Record<string, any>[];
  openModal: boolean;
  setOpenModal: (setter: boolean) => void;
  currentStep: StepConfig | null;
  setCurrentStep: (editedConfig: StepConfig | null) => void;
  onSave: (id: number) => void;
}

function StepsEditor({
  selectedLeads,
  openModal,
  setOpenModal,
  currentStep,
  setCurrentStep,
  onSave,
}: StepsEditorProps): React.ReactNode {
  const handleSaveStep = () => {
    onSave(currentStep ? currentStep.id : 0);
    setOpenModal(false);
    setCurrentStep(null);
  };

  const handleRichTextChange = (content: string) => {
    if (currentStep) {
      setCurrentStep({
        ...currentStep,
        message: { ...currentStep.message, body: content },
      });
    }
  };

  const handleWaitTimeChange = (time: number) => {
    if (currentStep) {
      setCurrentStep({ ...currentStep, waitTime: time });
    }
  };

  const handleSelectTag = (tag: string) => {};
  const handleSubjectSelectTag = (tag: string) => {
    if (currentStep) {
      setCurrentStep({
        ...currentStep,
        message: {
          ...currentStep.message,
          subject: currentStep.message.subject + `{{${tag}}}`,
        },
      });
    }
  };

  const extensions = useExtensions({
    placeholder: 'Write your content here...',
  });

  console.log(currentStep, 'the current step');

  const convertSecondsToTimeFormat = (totalSeconds: number) => {
    const days = Math.floor(totalSeconds / (24 * 3600));
    totalSeconds %= 24 * 3600;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);

    return {
      days,
      hours,
      minutes,
    };
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box
        p={4}
        borderRadius="8px"
        sx={{
          backgroundColor: 'background.paper',
          width: '80%',
          height: '80%',
          mx: 'auto',
          my: '5%',
          boxShadow: 24,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {currentStep?.id === 0 ? 'Warmup Content' : `Follow-Up Content`}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ flex: 1.2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                  m: 1,
                },
                [`& .${dividerClasses.root}`]: {
                  mx: 0.5,
                },
              }}
            >
              <Typography
                sx={{
                  px: 2,
                }}
              >
                Subject:{' '}
              </Typography>

              <Divider orientation="vertical" flexItem />
              <TextField
                fullWidth
                variant="standard"
                value={currentStep?.message.subject || ''}
                onChange={(e) =>
                  setCurrentStep({
                    ...currentStep!,
                    message: {
                      ...currentStep!.message,
                      subject: e.target.value,
                    },
                  })
                }
              />
              <Divider orientation="vertical" flexItem />
              <InsertTag
                availableTags={selectTagsFromLeads(selectedLeads)}
                onSelectTag={handleSubjectSelectTag}
              />
            </Box>

            <Box mt={3}>
              <Typography gutterBottom>Content:</Typography>
              <RichTextEditor
                extensions={extensions as any}
                content={currentStep?.message.body || ''}
                onUpdate={({ editor }) =>
                  handleRichTextChange(editor.getHTML())
                }
                renderControls={() => (
                  <EditorMenuControls
                    availableTags={selectTagsFromLeads(selectedLeads)}
                    onSelectTag={handleSelectTag}
                  />
                )}
              />
            </Box>

            <Stack
              gap={4}
              direction={'row'}
              display="flex"
              justifyItems={'right'}
              justifyContent={'right'}
              py={2}
            >
              <Stack display={'flex'} flexDirection={'row'} gap={2}>
                <Typography>Generate using GenAI</Typography>
                <IOSSwitch></IOSSwitch>
              </Stack>
              <Stack display={'flex'} flexDirection={'row'} gap={2}>
                <Typography>Rephrase using GenAI</Typography>
                <IOSSwitch></IOSSwitch>
              </Stack>
            </Stack>
          </Box>

          <Box sx={{ flex: 0.8 }}>
            <Typography gutterBottom>Wait Time Before Sending:</Typography>
            <TimeInput
              onChange={handleWaitTimeChange}
              initialValues={convertSecondsToTimeFormat(
                currentStep?.waitTime as number
              )}
            />

            <Box mt={3}>
              <Typography gutterBottom>Content Preview</Typography>
              <Box
                p={2}
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  height: '200px',
                  overflow: 'auto',
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentStep?.message.body ?? '',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
          <StyledButton onClick={() => setOpenModal(false)} variant="text">
            Cancel
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleSaveStep}
          >
            Save
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default StepsEditor;
