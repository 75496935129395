import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import type { ListOfTextInputsProps } from './types';

const ListOfTextInputs: React.FC<ListOfTextInputsProps> = ({
  name,
  onChange,
  value: _value = [''],
  
  sx,
}) => {
  const [values, setValues] = useState<string[]>(_value.length ? _value : ['']);
  const [errors, setErrors] = useState<boolean[]>([false]);

  // Handle input change
  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = [...values];
      const newErrors = [...errors];
      newValues[index] = event.target.value;
      newErrors[index] = !event.target.value.trim(); // Check for empty input
      setValues(newValues);
      setErrors(newErrors);
      onChange(newValues);
    };

  // Add new input field
  const handleAddInput = () => {
    setValues([...values, '']);
    setErrors([...errors, false]);
  };

  // Remove input field
  const handleDeleteInput = (index: number) => {
    const newValues = values.filter((_, i) => i !== index);
    const newErrors = errors.filter((_, i) => i !== index);
    setValues(newValues);
    setErrors(newErrors);
    onChange(newValues);
  };

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography color="text.primary" variant="h6">
        {name}
      </Typography>
      {typeof values === 'object' &&
        Array.isArray(values) &&
        values.map((value, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <TextField
              error={errors[index]}
              fullWidth
              helperText={errors[index] ? 'This field cannot be empty' : ''}
              label={`Point ${index + 1}`}
              onChange={handleChange(index)}
              value={value}
            />
            <IconButton
              aria-label={`Delete input ${index + 1}`}
              disabled={values.length === 1} // Disable delete if there's only one input
              onClick={() => { handleDeleteInput(index); }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      <Button
        color="primary"
        disabled={values.includes('')} // Disable add if there's an empty input
        onClick={handleAddInput}
        startIcon={<AddIcon />}
        sx={{ width: 'fit-content' }}
        variant="contained"
      >
        Add {name}
      </Button>
    </Box>
  );
};

export default ListOfTextInputs;
