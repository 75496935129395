import {
  AccountBalanceTwoTone,
  AutoFixHighTwoTone,
  DevicesTwoTone,
  DirectionsCarTwoTone,
  FitnessCenterTwoTone,
  FlightTwoTone,
  Handyman,
  HomeTwoTone,
  LocalHospitalTwoTone,
  MovieTwoTone,
  SchoolTwoTone,
  ShoppingCartTwoTone,
} from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

export const industries = [
  { name: 'Custom', icon: Handyman },
  { name: 'For you', icon: AutoFixHighTwoTone },
  { name: 'E-Commerce', icon: ShoppingCartTwoTone },
  { name: 'Real Estate', icon: HomeTwoTone },
  { name: 'Healthcare', icon: LocalHospitalTwoTone },
  { name: 'E-Learning', icon: SchoolTwoTone },
  { name: 'Technology and SaaS', icon: DevicesTwoTone },
  { name: 'Hospitality', icon: FlightTwoTone },
  { name: 'Automotive', icon: DirectionsCarTwoTone },
  { name: 'Financial Services', icon: AccountBalanceTwoTone },
  { name: 'Entertainment', icon: MovieTwoTone },
  { name: 'Fitness', icon: FitnessCenterTwoTone },
];

interface SidebarProps {
  activeIndustry: string;
  setActiveIndustry: React.Dispatch<React.SetStateAction<string>>;
}

const Sidebar: React.FC<SidebarProps> = ({
  activeIndustry,
  setActiveIndustry,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      gap={6}
      width={'30%'}
    >
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        Create Campaign
      </Typography>
      <Stack spacing={1.5} sx={{ width: '100%' }}>
        {industries.map((industry, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              borderRadius: 2,
              padding: 1.2,
              paddingLeft: 2.5,
              cursor: 'pointer',
              bgcolor:
                industry.name === activeIndustry
                  ? 'action.selected'
                  : 'transparent',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
            onClick={() => setActiveIndustry(industry.name)}
          >
            <industry.icon
              color={industry.name === activeIndustry ? 'primary' : 'inherit'}
            />
            <Typography
              variant="body1"
              color={
                industry.name === activeIndustry ? 'primary' : 'text.primary'
              }
            >
              {industry.name}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Sidebar;
