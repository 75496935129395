export const sampleEmailInboxCredentials = [
  {
    email: process.env.INBOX_EMAIL || '',
    password: process.env.INBOX_PASSWORD || '',
  },
];

//TODO to be changed later on
export const sampleIntegrationsMenu = [
  {
    integration_id: 'whatsapp',
    name: 'WhatsApp',
    description:
      'Integrate Alchemyst with WhatsApp to enhance your communication and customer engagement. This integration allows you to send real-time notifications, automate messages, and provide seamless customer support directly through WhatsApp. Improve responsiveness and streamline your communication channels for a better user experience.',
    enabled: false,
    installed: true,
    icon: '/whatsapp.png',
    tags: ['Communication', 'Customer Support', 'Automation'],
  },
  {
    integration_id: 'telegram',
    name: 'Telegram',
    description:
      'Integrate Alchemyst with Telegram to boost your communication and collaboration. With this integration, you can automate message sending, receive real-time updates, and interact with your audience or team effortlessly through Telegram channels and groups. Enhance your workflow and stay connected with minimal effort.',
    enabled: false,
    installed: true,
    icon: '/telegram.png',
    tags: ['Communication', 'Collaboration', 'Automation'],
  },
  {
    integration_id: 'slack',
    name: 'Slack',
    description:
      'Integrate Alchemyst with Slack to streamline your team communications and enhance productivity. With this integration, you can receive real-time updates, automate notifications, and collaborate efficiently within your Slack workspace. Experience seamless connectivity and increased workflow efficiency.',
    enabled: false,
    installed: true,
    icon: '/slack.png',
    tags: ['Communication', 'Collaboration', 'Productivity'],
  },
  {
    integration_id: 'hubspot',
    name: 'Hubspot',
    description:
      'Supercharge your marketing and sales outcomes by integrating Alchemyst with Hubspot. This integration allows you to leverage advanced analytics, automate customer interactions, and optimize your sales funnel. Transform your Hubspot experience with powerful insights and tools for better business decisions.',
    enabled: false,
    installed: true,
    icon: '/hubspot.svg',
    tags: ['Marketing', 'Sales', 'Analytics'],
  },
  {
    integration_id: 'google-workspace',
    name: 'Google Workspace',
    description:
      'Enhance your productivity by bringing Alchemyst directly into your Google Workspace. Seamlessly integrate with Google Drive, Google Docs, and other tools to streamline your workflow, manage tasks efficiently, and collaborate with your team in real-time. Boost your productivity and stay organized with this powerful integration.',
    enabled: false,
    installed: true,
    icon: '/workspace.webp',
    tags: ['Productivity', 'Collaboration', 'Workflow'],
  },
  {
    integration_id: 'discord',
    name: 'Discord',
    description:
      'Leverage the power of Alchemyst within your Discord server to enhance community engagement and streamline communications. This integration enables automated notifications, real-time updates, and improved collaboration among server members. Create a more connected and interactive community with Alchemyst and Discord.',
    enabled: false,
    installed: true,
    icon: '/discord.png',
    tags: ['Community', 'Communication', 'Engagement'],
  },
];
