export const sectors = [
    "Technology",
    "Finance",
    "Healthcare",
    "Education",
    "Retail",
    "Manufacturing",
    "Real Estate",
    "Energy",
    "Transportation",
    "Logistics",
    "Hospitality",
    "Media and Entertainment",
    "Aerospace",
    "Automotive",
    "Construction",
    "Agriculture",
    "Food and Beverage",
    "Pharmaceuticals",
    "Telecommunications",
    "E-commerce",
    "Insurance",
    "Government",
    "Non-Profit",
    "Legal",
    "Sports and Recreation",
    "Consulting",
    "Fashion and Apparel",
    "Beauty and Personal Care",
    "Event Management",
    "Environmental Services",
    "Mining",
    "Utilities",
    "Cybersecurity",
    "Advertising and Marketing",
    "Biotechnology",
    "Travel and Tourism",
    "Art and Design",
    "Publishing",
    "Electronics",
    "Education Technology (EdTech)",
    "Financial Technology (FinTech)",
    "Health Technology (HealthTech)"
];
