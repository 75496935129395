import { ModalContent, StyledButton, StyledModal } from '@/constants/themes';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface EditStepModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

const EditStepModal: React.FC<EditStepModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [stepName, setStepName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleSubmit = () => {
    if (!stepName.trim()) {
      setError('Step name is required');
      return;
    }

    setLoading(true);
    setTimeout(() => {
      onSubmit(stepName);
      setLoading(false);
      onClose();
    }, 1000);
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-step"
      closeAfterTransition
    >
      <ModalContent sx={{ maxWidth: '40vw', padding: 4, borderRadius: 1 }}>
        <Typography variant="h6" gutterBottom>
          Edit Step
        </Typography>

        <TextField
          label="Step Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={stepName}
          onChange={(e) => setStepName(e.target.value)}
          error={!!error}
          helperText={error}
          autoFocus
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <StyledButton variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Save'
            )}
          </StyledButton>
        </Box>
      </ModalContent>
    </StyledModal>
  );
};

export default EditStepModal;
