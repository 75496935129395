import { CampaignWorkflowTemplate } from '@/types/campaign';
import { create } from 'zustand';

interface TemplateBuilderStoreType {
  campaignTemplates: {
    name: string;
    description: string;
    yaml: string;
    category: string;
  }[];
  currentCampaignTemplate: CampaignWorkflowTemplate | null;
  setStoreState: (newState: Partial<TemplateBuilderStoreType>) => void;
}

const useTemplateBuilderStore = create<TemplateBuilderStoreType>((set) => ({
  // Initial state setup
  campaignTemplates: [],
  currentCampaignTemplate: null,
  // Generic setState method
  setStoreState: (newState: Partial<TemplateBuilderStoreType>) => {
    set((state) => ({ ...state, ...newState }));
  },
}));

export default useTemplateBuilderStore;
