import { inboxCommonStyles } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { setImapCredentials } from '@/utils/imapCredentials';
import Close from '@mui/icons-material/Close';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';

interface ImapModalProps {
  open: boolean;
  onClose: (event?: {}, reason?: 'escapeKeyDown' | 'backdropClick') => void;
  setIsCredentialsSet: React.Dispatch<React.SetStateAction<boolean>>;
  setInboxAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

function ImapModal({
  open,
  onClose,
  setIsCredentialsSet,
  setInboxAuthenticated,
}: ImapModalProps): React.ReactNode {
  const { inboxEmails, setStoreState } = useAlchemystStoreForAi((store) => {
    return {
      inboxEmails: store.inboxEmails,
      setStoreState: store.setStoreState,
    };
  });

  const [emailSettings, setEmailSettings] = useState<{
    imapEmail: string;
    imapPassword: string;
    meetingLink: string;
    provider: string;
    providerIMAPHost: string;
    providerIMAPPort: number;
    providerSMTPHost: string;
    providerSMTPPort: number;
    providerInboxName: string;
    providerSentBoxName: string;
    loading: boolean;
    error: string;
  }>({
    imapEmail: '',
    imapPassword: '',
    meetingLink: '',
    provider: 'gmail',
    providerIMAPHost: '',
    providerIMAPPort: 0,
    providerSMTPPort: 0,
    providerSMTPHost: '',
    providerInboxName: '',
    providerSentBoxName: '',
    loading: false,
    error: '',
  });

  /**
   * Validates the email and meeting link inputs.
   * @returns {boolean} - Returns true if inputs are valid, false otherwise.
   */
  const validateInputs = useCallback(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!emailRegex.test(emailSettings.imapEmail)) {
      setEmailSettings((prev) => ({
        ...prev,
        error: 'Invalid email format',
      }));
      return false;
    }
    if (!urlRegex.test(emailSettings.meetingLink)) {
      setEmailSettings((prev) => ({
        ...prev,
        error: 'Invalid meeting link format',
      }));
      return false;
    }
    setEmailSettings((prev) => ({
      ...prev,
      error: '',
    }));
    return true;
  }, [emailSettings.imapEmail, emailSettings.meetingLink]);

  const handleInputChange =
    (field: Exclude<keyof typeof emailSettings, 'error' | 'loading'>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmailSettings((prev) => ({
        ...prev,
        [field]: event.target.value,
        error: '', // Clear error when input is changed
      }));
    };

  const handleProviderChange = (event: SelectChangeEvent) => {
    setEmailSettings((prev) => ({
      ...prev,
      provider: event.target.value,
      error: '', // Clear error when input is changed
    }));
  };

  /**
   * Handles the save action by setting IMAP credentials.
   * Sets loading state during the process and updates authentication status.
   */
  const handleSave = async () => {
    if (!validateInputs()) return;
    setEmailSettings((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const isSet = await setImapCredentials(
        emailSettings.imapEmail,
        emailSettings.imapPassword,
        emailSettings.meetingLink,
        emailSettings.provider,
        emailSettings.providerIMAPHost,
        emailSettings.providerIMAPPort,
        emailSettings.providerSMTPHost,
        emailSettings.providerSMTPPort,
        emailSettings.providerInboxName,
        emailSettings.providerSentBoxName
      );
      if (isSet) {
        setIsCredentialsSet(true);
        setInboxAuthenticated(true);
        onClose();

        //add to inbox email to store
        const newInboxEmails = [...inboxEmails, emailSettings.imapEmail];
        setStoreState({
          inboxEmails: newInboxEmails,
        });
      } else
        setEmailSettings((prev) => ({
          ...prev,
          error: 'Failed to set IMAP credentials.',
        }));
    } finally {
      setEmailSettings((prev) => ({
        ...prev,
        imapEmail: '',
        imapPassword: '',
        meetingLink: '',
        provider: 'gmail',
        providerIMAPHost: '',
        providerIMAPPort: 0,
        providerSMTPHost: '',
        providerSMTPPort: 0,
        providerInboxName: '',
        providerSentBoxName: '',
        loading: false,
      }));
    }
  };

  return (
    <Modal
      aria-describedby="imap-modal-description"
      aria-labelledby="imap-modal-title"
      disableEscapeKeyDown={inboxEmails.length === 0}
      onClose={onClose}
      open={open}
    >
      <Box
        sx={{
          ...inboxCommonStyles.modalBox,
          width: 800,
          padding: 4,
          position: 'relative',
        }}
      >
        <Typography
          id="imap-modal-title"
          sx={{
            marginBottom: 4,
          }}
          variant="h5"
        >
          Inbox Credentials
        </Typography>

        {inboxEmails.length > 0 && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            size="large"
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <Close />
          </IconButton>
        )}

        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          marginTop={2}
          width="100%"
        >
          <TextField
            error={
              Boolean(emailSettings.error) &&
              emailSettings.error.includes('email')
            }
            fullWidth
            helperText={
              emailSettings.error.includes('email') ? emailSettings.error : ''
            }
            label="Email"
            onChange={handleInputChange('imapEmail')}
            required
            type="email"
            value={emailSettings.imapEmail}
            variant="outlined"
          />

          <TextField
            fullWidth
            label="App Password"
            onChange={handleInputChange('imapPassword')}
            required
            type="password"
            value={emailSettings.imapPassword}
            variant="outlined"
          />
          <TextField
            error={
              Boolean(emailSettings.error) &&
              emailSettings.error.includes('meeting link')
            }
            fullWidth
            helperText={
              emailSettings.error.includes('meeting link')
                ? emailSettings.error
                : ''
            }
            label="Meeting Link"
            onChange={handleInputChange('meetingLink')}
            required
            type="text"
            value={emailSettings.meetingLink}
            variant="outlined"
          />
          <Select
            defaultValue="gmail"
            fullWidth
            onChange={handleProviderChange}
            placeholder="Select Provider"
            required
            value={emailSettings.provider}
            variant="outlined"
          >
            <MenuItem value="gmail">Gmail</MenuItem>
            <MenuItem value="outlook">Outlook</MenuItem>
            <MenuItem value="yahoo">Yahoo</MenuItem>
            <MenuItem value="zoho">Zoho</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </Select>
          {emailSettings.provider === 'others' && (
            <Box display="flex" flexDirection="column" gap={2} width="100%">
              <TextField
                fullWidth
                label="Provider IMAP Host"
                onChange={handleInputChange('providerIMAPHost')}
                required
                type="text"
                value={emailSettings.providerIMAPHost}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Provider IMAP Port"
                onChange={handleInputChange('providerIMAPPort')}
                required
                type="number"
                value={emailSettings.providerIMAPPort}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Provider SMTP Host"
                onChange={handleInputChange('providerSMTPHost')}
                required
                type="text"
                value={emailSettings.providerSMTPHost}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Provider SMTP Port"
                onChange={handleInputChange('providerSMTPPort')}
                required
                type="number"
                value={emailSettings.providerSMTPPort}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Provider Inbox Name"
                onChange={handleInputChange('providerInboxName')}
                required
                type="text"
                value={emailSettings.providerInboxName}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Provider Sent Box Name"
                onChange={handleInputChange('providerSentBoxName')}
                required
                type="text"
                value={emailSettings.providerSentBoxName}
                variant="outlined"
              />
            </Box>
          )}
          {emailSettings.error ? (
            <Typography color="error" variant="body2">
              {emailSettings.error}
            </Typography>
          ) : null}
          <Button
            color="primary"
            disabled={
              emailSettings.loading ||
              !emailSettings.imapEmail ||
              !emailSettings.imapPassword ||
              !emailSettings.meetingLink ||
              Boolean(emailSettings.error) ||
              (emailSettings.provider === 'others' &&
                (!emailSettings.providerIMAPHost ||
                  !emailSettings.providerIMAPPort))
            }
            fullWidth
            onClick={handleSave}
            startIcon={
              emailSettings.loading ? <CircularProgress size={20} /> : null
            }
            variant="contained"
          >
            {emailSettings.loading ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ImapModal;
