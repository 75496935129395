import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { LangChainJSON } from '@/types/ai/messages';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { batchedPromiseResolution } from '@/utils/processing/batch';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

function BatchedWebEnrichment() {
  const [fieldNames, setFieldNames] = useState<string[]>([]);
  const [sourceField, setSourceField] = useState<string | null>(null);
  const [targetField, setTargetField] = useState<string | null>(null);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const { setStoreState, leads, selectedLeads } = useAlchemystStoreForAi(
    (store) => ({
      leads: store.leads,
      selectedLeads: store.selectedLeads,
      setStoreState: store.setStoreState,
    })
  );

  const [workStatus, setWorkStatus] = useState<
    'open' | 'closed' | 'working' | 'saved'
  >('open');

  const [userPrompt, setUserPrompt] = useState<string | null>(null);

  // const [token, setToken] = useState<string | null>(null);
  // const { getToken } = useAuth();

  // const getAuthToken = async () => {
  //   const token = await getToken();
  //   setToken(token);
  // };

  // useEffect(() => {
  //   getAuthToken();
  // }, []);

  useEffect(() => {
    setFieldNames(Object.keys(leads[0] ?? {}));
  }, [leads]);

  const isValidUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  /**
   * Returns an object containing the translated text information, capturing the app's key features and user experience.
   * Provides essential information about the app's functionality, including AI-driven language translation.
   *
   * @param {Object} headlessScraperQuery An object containing the user's prompt and the URL of the webpage to scrape.
   * @returns {Object} An object containing theTranslated text information.
   */
  const getHeadlessScrapingResults = async (headlessScraperQuery: {
    userPrompt: string;
    dataForSearchQuery: Record<string, any>;
  }) => {
    const res = await fetchWithRewrites('/api/leads/augment/web', {
      method: 'POST',
      body: JSON.stringify(headlessScraperQuery),
      signal: AbortSignal.timeout(600_000),
      keepalive: true,
    });

    const res_json = await res.json();
    let resp: LangChainJSON | null = null;
    if (res.status === 201) {
      resp = res_json;
    }

    console.log('Input going to the api = ', headlessScraperQuery);
    console.log('Resulting JSON = ', resp);
    return resp;
  };

  /**
   * Retrieves headless scraping results by querying the headless scraper
   * with the provided user prompt and page URL.
   * @param {HeadlessScraperQuery} query - An object containing the user prompt and page URL.
   * @returns {LangChainJSON|null} The results of the headless scraping operation, or null if an error occurs.
   */
  const getBatchedHeadlessScrapingResults = async (
    headlessScraperQueries: {
      userPrompt: string;
      dataForSearchQuery: Record<string, any>;
    }[],
    statusUpdateFunction: (status: 'working' | 'open' | 'closed') => any
  ) => {
    statusUpdateFunction('working');
    const promisesList = headlessScraperQueries.map(
      async (headlessScraperQuery) => {
        try {
          return await getHeadlessScrapingResults(headlessScraperQuery);
        } catch (error) {
          return null;
        }
      }
    );
    // const results = await Promise.all(promisesList);
    const results = (await batchedPromiseResolution(
      promisesList as never,
      20,
      'all'
    )) satisfies LangChainJSON[];

    console.log(results[0]);

    statusUpdateFunction('open');

    return results.map((result, idx) => ({
      query: headlessScraperQueries[idx],
      result,
    }));
  };

  const checkIfObjectIsPresentInArray = (
    object: Record<string, any>,
    objectsArray: Record<string, any>[],
    exclude: string[]
  ) => {
    return objectsArray.some((objectEntry) => {
      let equalityStatus = true;

      Object.keys(objectEntry)
        .filter((key) => !exclude.includes(key))
        .forEach((fieldKey) => {
          equalityStatus =
            equalityStatus && objectEntry[fieldKey] === object[fieldKey];
        });

      return equalityStatus;
    });
  };

  const handleFetchAugmentorData = async () => {
    try {
      setWorkStatus('working');
      const currentResults = await getBatchedHeadlessScrapingResults(
        selectedLeads.map((lead) => ({
          dataForSearchQuery: {
            ...lead,
            [targetField ?? 'New column']: undefined,
          },
          userPrompt: userPrompt ?? 'No user prompt',
        })),
        setWorkStatus
      );
      console.log('Received data = ', JSON.stringify(currentResults));
      if (currentResults.length === 0) {
        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              severity: 'error',
              message: "Couldn't get a response from Augmentor API.",
              type: 'alert',
            },
          ],
        });
        return;
      }
      let leadsModified: typeof leads = leads.map((leadEntry, idx) => {
        if (
          checkIfObjectIsPresentInArray(leadEntry, selectedLeads, [
            targetField ?? 'New column',
          ])
        ) {
          const modification = (currentResults ?? [])
            .filter((currentResultEntry) => {
              let equalityStatus = true;

              Object.keys(currentResultEntry.query.dataForSearchQuery)
                .filter((key) => ![targetField ?? 'New column'].includes(key))
                .forEach((fieldKey) => {
                  equalityStatus =
                    equalityStatus &&
                    currentResultEntry.query.dataForSearchQuery[fieldKey] ===
                      leadEntry[fieldKey];
                });

              return equalityStatus;
            })
            .pop()!;

          const modificationResult =
            modification.result?.lc_kwargs.content.toString();
          console.log('Modifications made = ');
          console.log(modification);
          return {
            ...leadEntry,
            [targetField ?? 'New column']: modificationResult,
          };
        } else {
          return {
            ...leadEntry,
            [targetField ?? 'New column']:
              leadEntry[targetField ?? 'New column'] ?? '',
          };
        }
      });
      console.log(
        'Augmented lead column data = ',
        leadsModified[0][targetField ?? 'New column']
      );
      setStoreState({ leads: leadsModified });
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'success',
            message: 'Batched Web Enrichment complete.',
            type: 'alert',
          },
        ],
      });
    } catch (error) {
      console.log('Error: ', (error as Error).message);
    } finally {
      setWorkStatus('open');
    }
  };

  const handleChangeUserPrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPrompt(e.target.value);
  };

  return selectedLeads.length > 0 ? (
    <Stack padding={2} spacing={3} width={'100%'}>
      <FormControl fullWidth>
        <InputLabel id="select-target-field">Target Field</InputLabel>
        <Select
          labelId="select-target-field"
          id="target-field"
          value={targetField ?? fieldNames[0]}
          label="Target field"
          onChange={(e) => setTargetField(e.target.value.toString())}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '50vh',
                width: 'auto',
                overflowY: 'auto',
              },
            },
          }}
        >
          {fieldNames.map((fieldName) => (
            <MenuItem value={fieldName}>{fieldName}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Query"
        value={userPrompt ?? ''}
        multiline
        minRows={5}
        maxRows={6}
        onChange={handleChangeUserPrompt}
        placeholder="Enter what you want from the data."
      />
      <StyledButton
        variant="contained"
        onClick={handleFetchAugmentorData}
        disabled={!userPrompt || ['saved', 'working'].includes(workStatus)}
        endIcon={
          ['saved', 'working'].includes(workStatus) && (
            <CircularProgress size={'1vh'} />
          )
        }
      >
        {!['saved', 'working'].includes(workStatus) ? 'Augment' : 'working'}
      </StyledButton>
    </Stack>
  ) : (
    <Typography variant="body1">
      Please select atleast one lead to start
    </Typography>
  );
}

export default BatchedWebEnrichment;
