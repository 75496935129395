import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { inboxCommonStyles } from '../../constants/themes';

interface AddEmailModalProps {
  open: boolean;
  onClose: () => void;
  handleAddEmail: (
    newEmails: string[],
    deletedEmails: string[]
  ) => Promise<void>;
}

function AddEmailModal({ open, onClose, handleAddEmail }: AddEmailModalProps) {
  const [emailList, setEmailList] = useState<string[]>(['']);
  const [initialEmails, setInitialEmails] = useState<string[]>([]);
  const [emailErrors, setEmailErrors] = useState<boolean[]>([false]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const storedEmails = localStorage.getItem('emailsToCheck');
    if (storedEmails) {
      const parsedEmails = JSON.parse(storedEmails);
      setEmailList(parsedEmails);
      setInitialEmails(parsedEmails);
    }
  }, [open]);

  const isValidEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const updateEmail = (index: number, value: string) => {
    const updatedEmails = [...emailList];
    updatedEmails[index] = value;
    setEmailList(updatedEmails);

    const updatedErrors = [...emailErrors];
    updatedErrors[index] = !isValidEmail(value);
    setEmailErrors(updatedErrors);
  };

  const addEmailField = () => {
    setEmailList([...emailList, '']);
    setEmailErrors([...emailErrors, false]);
  };

  const removeEmailField = (email: string) => {
    const index = emailList.indexOf(email);
    const updatedEmails = emailList.filter((_, i) => i !== index);
    const updatedErrors = emailErrors.filter((_, i) => i !== index);
    setEmailList(updatedEmails);
    setEmailErrors(updatedErrors);
  };

  const handleSubmitEmails = async () => {
    if (!emailErrors.some((error) => error)) {
      setLoading(true);
      const newEmails = emailList.filter(
        (email) => !initialEmails.includes(email)
      );
      const removedEmails = initialEmails.filter(
        (email) => !emailList.includes(email)
      );

      await handleAddEmail(newEmails, removedEmails);
      setEmailList(['']);
      setEmailErrors([false]);
      setLoading(false);
      onClose();
    }
  };

  const isSubmitDisabled =
    emailList.some((email) => email === '') ||
    emailErrors.some((error) => error);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="add-emails-modal">
      <Box sx={inboxCommonStyles.modalBox}>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Add Emails
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          Add new emails to the inbox
        </Typography>
        {emailList.map((email, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
          >
            <TextField
              fullWidth
              id={`email-${index}`}
              label={`Email ${index + 1}`}
              variant="outlined"
              value={email}
              error={emailErrors[index]}
              helperText={emailErrors[index] ? 'Invalid email format' : ''}
              onChange={(event) => updateEmail(index, event.target.value)}
              sx={{ mr: 1 }}
            />
            {emailList.length > 1 && (
              <IconButton
                onClick={() => removeEmailField(email)}
                color="secondary"
                sx={{ mr: 1 }}
                aria-label="Remove email field"
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Box>
        ))}
        <Button
          variant="text"
          color="primary"
          onClick={addEmailField}
          sx={{ mb: 2 }}
          startIcon={<AddIcon />}
          aria-label="Add another email field"
        >
          Add Another Email
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitEmails}
          disabled={isSubmitDisabled}
          aria-label="Confirm email addition"
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          {loading ? 'Confirming...' : 'Confirm Add Emails'}
        </Button>
      </Box>
    </Modal>
  );
}

export default AddEmailModal;
