import { RightSidePanel } from '@/components/common/Sidepanel';
import { fieldOptions } from '@/constants/campaigns/fieldOptions';
import useTemplateBuilderStore from '@/hooks/useTemplateBuilderStore';
import type { CampaignWorkflowTemplate, Step } from '@/types/campaign';
import { generateFieldData } from '@/utils/campaigns/generateFieldData';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import YAML from 'yaml';
import AddFieldOptions from '../fields/AddFieldOptions';
import CampaignBuilderHeader from './CampaignBuilderHeader';
import CustomStepper from './CustomStepper';
import FieldsList from './FieldsList';
import StepNavigation from './StepNavigation';
import { navigate } from 'raviger';

interface TitleEditingState {
  name: string;
  description: string;
  isEditingName: boolean;
  isEditingDescription: boolean;
}

const initialTitleEditingState: TitleEditingState = {
  name: '',
  description: '',
  isEditingName: false,
  isEditingDescription: false,
};

const CampaignBuilder = () => {
  const [error, setError] = useState<string | null>(null);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [titleEditing, setTitleEditing] = useState<TitleEditingState>(
    initialTitleEditingState
  );
  const [openSidePanel, setOpenSidePanel] = useState<boolean>(true);

  const currentCampaignTemplate = useTemplateBuilderStore(
    (store) => store.currentCampaignTemplate
  );
  const campaignTemplates = useTemplateBuilderStore(
    (store) => store.campaignTemplates
  );
  const setStoreState = useTemplateBuilderStore((store) => store.setStoreState);

  const fetchTemplateFromLocalStorage = useCallback(() => {
    const baseTemplate = localStorage.getItem('generatedCampaignTemplate');

    if (!baseTemplate) {
      setError('No campaign template found in local storage');
      return;
    }

    try {
      const parsedTemplate = YAML.parse(
        baseTemplate
      ) as CampaignWorkflowTemplate;
      setStoreState({ currentCampaignTemplate: parsedTemplate });
      setTitleEditing({
        name: parsedTemplate.name,
        description: parsedTemplate.description,
        isEditingName: false,
        isEditingDescription: false,
      });
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : 'Error parsing YAML';
      setError(errorMessage);
      console.error('Error parsing YAML:', err);
    }
  }, [setStoreState]);

  useEffect(() => {
    fetchTemplateFromLocalStorage();
  }, [fetchTemplateFromLocalStorage]);

  const updateCurrentCampaignTemplate = (
    updatedTemplate: CampaignWorkflowTemplate
  ) => {
    localStorage.setItem(
      'generatedCampaignTemplate',
      YAML.stringify(updatedTemplate)
    );
    setStoreState({
      currentCampaignTemplate: updatedTemplate,
    });
  };

  const handleAddStep = useCallback(
    (leftIndex: number, stepName: string) => {
      if (!currentCampaignTemplate) return;

      const newStep: Step = { step_name: stepName, fields: [] };
      const updatedSteps =
        leftIndex === -1
          ? [newStep, ...currentCampaignTemplate.steps]
          : [
              ...currentCampaignTemplate.steps.slice(0, leftIndex + 1),
              newStep,
              ...currentCampaignTemplate.steps.slice(leftIndex + 1),
            ];

      updateCurrentCampaignTemplate({
        ...currentCampaignTemplate,
        steps: updatedSteps,
      });
    },
    [currentCampaignTemplate, updateCurrentCampaignTemplate]
  );

  const handleRemoveStep = useCallback(
    (index: number) => {
      if (!currentCampaignTemplate) return;

      const updatedSteps = currentCampaignTemplate.steps.filter(
        (_, i) => i !== index
      );
      updateCurrentCampaignTemplate({
        ...currentCampaignTemplate,
        steps: updatedSteps,
      });
    },
    [currentCampaignTemplate, updateCurrentCampaignTemplate]
  );

  const handleEditStep = useCallback(
    (index: number, value: string) => {
      if (!currentCampaignTemplate) return;

      const updatedSteps = currentCampaignTemplate.steps.map((step, i) =>
        i === index ? { ...step, step_name: value } : step
      );

      updateCurrentCampaignTemplate({
        ...currentCampaignTemplate,
        steps: updatedSteps,
      });
    },
    [currentCampaignTemplate, updateCurrentCampaignTemplate]
  );

  const handleUpdateCampaignHeader = useCallback(
    (
      key: keyof Pick<CampaignWorkflowTemplate, 'name' | 'description'>,
      value: string
    ) => {
      if (!currentCampaignTemplate) return;

      updateCurrentCampaignTemplate({
        ...currentCampaignTemplate,
        [key]: value,
      });
      setTitleEditing((prev) => ({ ...prev, [key]: value }));
    },
    [currentCampaignTemplate, updateCurrentCampaignTemplate]
  );

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!currentCampaignTemplate) {
    return <Typography>No campaign template found in local storage</Typography>;
  }

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    console.log(source, destination);
    if (!destination) return;

    if (destination.droppableId === 'fields') {
      const typeOfField = fieldOptions[source.index].type;
      const fieldData = generateFieldData(typeOfField);
      if (!fieldData) return;
      const updatedFields = [
        ...currentCampaignTemplate.steps[activeStepIndex].fields,
        fieldData,
      ];
      const updatedCampaign = { ...currentCampaignTemplate };
      const step = updatedCampaign.steps[activeStepIndex];
      if (step) {
        step.fields = updatedFields;
        updateCurrentCampaignTemplate(updatedCampaign);
      }
    }
  };

  const handleSaveTemplate = () => {
    const yaml = YAML.stringify({
      steps: currentCampaignTemplate.steps,
    });
    const template = {
      name: currentCampaignTemplate.name,
      description: currentCampaignTemplate.description,
      category: 'Custom',
      yaml,
    };
    const localStorageTemplatesString =
      localStorage.getItem('campaignTemplates');
    const localStorageTemplates = localStorageTemplatesString
      ? JSON.parse(localStorageTemplatesString)
      : [];
    localStorage.setItem(
      'campaignTemplates',
      JSON.stringify([...localStorageTemplates, template])
    );
    localStorage.removeItem('generatedCampaignTemplate');
    navigate('/campaigns');
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="addFieldOptions">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            <RightSidePanel
              open={openSidePanel}
              setOpen={setOpenSidePanel}
              panelWidth="22vw"
            >
              <AddFieldOptions onClick={() => {}} />
            </RightSidePanel>
          </Box>
        )}
      </Droppable>

      <Droppable droppableId="fields">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              padding: '2rem',
              width: openSidePanel ? 'calc(100% - 22vw)' : '100%',
            }}
          >
            <CampaignBuilderHeader
              titleEditing={titleEditing}
              setTitleEditing={setTitleEditing}
              handleUpdateCampaign={handleUpdateCampaignHeader}
              onSave={handleSaveTemplate}
            />

            <CustomStepper
              activeStep={activeStepIndex}
              onAddStep={handleAddStep}
              onEditStep={handleEditStep}
              onRemoveStep={handleRemoveStep}
              onStepChange={setActiveStepIndex}
              steps={currentCampaignTemplate.steps}
            />

            {currentCampaignTemplate.steps[activeStepIndex] && (
              <FieldsList
                activeStepIndex={activeStepIndex}
                updateCurrentCampaignTemplate={updateCurrentCampaignTemplate}
              />
            )}

            <StepNavigation
              activeStep={activeStepIndex}
              setActiveStep={setActiveStepIndex}
            />

            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CampaignBuilder;
