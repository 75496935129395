import { ModalContent, StyledButton, StyledModal } from '@/constants/themes';
import { Add } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface CustomStepperConnectorProps {
  leftIndex: number;
  onAddStep: (leftIndex: number, stepName: string) => void;
}

const CustomStepperConnector: React.FC<CustomStepperConnectorProps> = ({
  leftIndex,
  onAddStep,
}) => {
  const [open, setOpen] = useState(false);
  const [stepName, setStepName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setStepName('');
    setError('');
    setOpen(false);
  };

  const handleAddStep = () => {
    if (!stepName.trim()) {
      setError('Step name is required');
      return;
    }

    setLoading(true);
    try {
      setTimeout(() => {
        onAddStep(leftIndex, stepName);
        setLoading(false);
        handleClose();
      }, 1000);
    } catch (err) {
      setLoading(false);
      setError('An error occurred while adding the step.');
      console.error(err);
    }
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: 2,
          backgroundColor: 'gray',
          position: 'relative',
          mt: 2,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'action.hover',
            cursor: 'pointer',
          }}
          onClick={handleOpen}
        >
          <Add />
        </IconButton>
      </Box>

      {/* Modal */}
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-step-modal"
        closeAfterTransition
      >
        <ModalContent
          sx={{
            maxWidth: '40vw',
            padding: 4,
            borderRadius: 1,
            maxHeight: '80vh',
            overflowY: 'auto',
            backgroundColor: 'background.paper',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Step
          </Typography>

          <TextField
            label="Step Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={stepName}
            onChange={(e) => setStepName(e.target.value)}
            error={Boolean(error)}
            helperText={error}
            autoFocus
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <StyledButton
              variant="outlined"
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleAddStep}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Add'
              )}
            </StyledButton>
          </Box>
        </ModalContent>
      </StyledModal>
    </>
  );
};

export default CustomStepperConnector;
