export const technologies = [
    "Artificial Intelligence (AI)",
    "Machine Learning (ML)",
    "Blockchain",
    "Cloud Computing",
    "Internet of Things (IoT)",
    "Cybersecurity",
    "Big Data",
    "Augmented Reality (AR)",
    "Virtual Reality (VR)",
    "5G Networks",
    "Quantum Computing",
    "Edge Computing",
    "Natural Language Processing (NLP)",
    "DevOps",
    "Robotic Process Automation (RPA)",
    "3D Printing",
    "Mobile App Development",
    "Web Development",
    "Game Development",
    "Data Analytics",
    "Digital Marketing",
    "E-commerce Platforms",
    "Content Management Systems (CMS)",
    "Database Management Systems (DBMS)",
    "Networking Technologies",
    "Programming Languages (e.g., Python, Java, JavaScript, C++)",
    "Artificial Neural Networks (ANNs)",
    "Autonomous Vehicles",
    "Computer Vision",
    "Cryptography",
    "Software Development Lifecycle (SDLC)",
    "Embedded Systems",
    "Progressive Web Apps (PWAs)",
    "Serverless Computing",
    "Containers (e.g., Docker, Kubernetes)",
    "Virtualization",
    "Open Source Technologies",
    "Search Engine Optimization (SEO)",
    "User Experience (UX) Design",
    "Human-Computer Interaction (HCI)",
    "Microservices Architecture",
    "API Development",
    "Integration Platforms",
    "Energy Tech",
    "Environmental Tech",
    "Smart Home Technologies",
    "Wearable Technologies",
    "Chatbots and Conversational AI",
    "Video Conferencing Platforms",
    "Social Media Technologies",
    "Audio Processing Technologies"
];
