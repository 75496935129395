export const jobTitles = [
  'CEO',
  'CTO',
  'CFO',
  'COO',
  'CMO',
  'VP of Sales',
  'VP of Marketing',
  'VP of Engineering',
  'VP of Operations',
  'Product Manager',
  'Project Manager',
  'Software Engineer',
  'Data Scientist',
  'Machine Learning Engineer',
  'DevOps Engineer',
  'UI/UX Designer',
  'Graphic Designer',
  'Marketing Manager',
  'Sales Manager',
  'HR Manager',
  'Operations Manager',
  'Business Analyst',
  'Account Manager',
  'Customer Success Manager',
  'Financial Analyst',
  'Social Media Manager',
  'Content Marketing Manager',
  'SEO Specialist',
  'Copywriter',
  'Technical Support Specialist',
  'IT Manager',
  'System Administrator',
  'Network Engineer',
  'Database Administrator',
  'Quality Assurance Engineer',
  'Legal Counsel',
  'Supply Chain Manager',
  'Recruiter',
  'Training and Development Specialist',
  'Public Relations Manager',
  'Digital Marketing Specialist',
  'E-commerce Manager',
  'Risk Manager',
  'Compliance Officer',
  'Chief Data Officer (CDO)',
  'Chief Information Officer (CIO)',
  'Chief Revenue Officer (CRO)',
  'Chief Product Officer (CPO)',
];
