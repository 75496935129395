type Policy = 'any' | 'allSettled' | 'all';

/**
 * Splits an array into batches of a specified size.
 *
 * @param arrayToSplit The input array to be batched.
 * @param batchSize The desired size of each batch.
 * @returns A 2D array with the input array split into batches.
 */
export function batchArray<T>(arrayToSplit: T[], batchSize: number): T[][] {
  // Calculate the number of batches needed to split the array.
  const numBatches = Math.ceil(arrayToSplit.length / batchSize);

  // Create an array to hold the batches, filled with zeros.
  const batchedArray: any[][] = Array(numBatches)
    .fill(0)
    .map((_, i) => {
      // Slice the original array to create a batch of the specified size.
      return arrayToSplit.slice(i * batchSize, (i + 1) * batchSize);
    });

  // Return the array of batches.
  return batchedArray;
}

async function resolveBatch<T>(
  callbacks: (() => Promise<T>)[],
  resolutionPolicy: Policy
): Promise<T[]> {
  console.log('At resolveBatch now.');
  const promises = callbacks.map((callback) => callback);
  console.log('Hi');
  if (resolutionPolicy === 'all') {
    // @ts-ignore
    return Promise.all(promises);
  } else if (resolutionPolicy === 'any') {
    // Promise.any resolves to the first fulfilled value
    // @ts-ignore
    return [await Promise.any(promises)];
  }
  const settled = await Promise.allSettled(promises);
  // @ts-ignore
  return settled
    .filter((result) => result.status === 'fulfilled')
    .map((result) => result.value);
}

/**
 * Batches multiple asynchronous callbacks and returns a promise that resolves
 * to an array of values when all of the callbacks have completed.
 *
 * @param callbacks - An array of asynchronous callbacks, each of which returns
 * a promise that resolves to a value of type `T`.
 * @param resolutionPolicy - The policy to use for resolving the array of promise
 * values returned by the callbacks. Can be one of:
 *   - `'any'`: Returns the first value that is resolved. A promise will be
 *     rejected if any of the values are not resolved.
 *   - `'all'`: Returns an array of values when all of the values are resolved.
 *   - `'allSettled'`: Returns an array of values when all of the values are
 *     settled, regardless of whether they are resolved or rejected.
 *
 * @template T - The type of values returned by the callbacks.
 * @returns A promise that resolves to an array of values when all of the callbacks
 * are completed.
 */
export async function batchedPromiseResolution<T>(
  promiseCallbacks: ((...args: any) => Promise<T>)[],
  batchSize = 20,
  resolutionPolicy: Policy = 'all'
): Promise<T[]> {
  console.log(`Resolving promises in batches of ${batchSize}`);
  const results: T[] = [];
  const batch: (() => Promise<T>)[] = [];

  const callbackBatches = await batchArray(promiseCallbacks, batchSize);
  // Iterate through the array of promise callbacks
  for (const batch of callbackBatches) {
    // batch.push(callback);
    // When the batch is full, resolve it
    const resolved = await resolveBatch<T>(batch, resolutionPolicy);
    results.push(...resolved);
  }

  // Resolve any remaining items in the batch
  if (batch.length > 0) {
    const resolved = await resolveBatch(batch, resolutionPolicy);
    results.push(...resolved);
  }

  return results;
}
